var ONBOARDING_API_BASE_PATH = "OnBoardingAPI/api/onboarding/v1";
var CORE_WEB_API_BASE_PATH = "CoreWebAPI/api";
var CORP_API_BASE_PATH = "corpapi/api/corp/v1";
export var ServiceConstant = {
    AUTHORIZATION: "Authorization",
    ACCEPT_LANGUAGE: "Accept-Language",
    USER_DETAILS: "user_details",
    CORP_API_BASE_PATH: CORP_API_BASE_PATH,
    ADMIN_DASHBOARD_WIDGET_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Widgets"),
    CLIENT_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Clients"),
    ACCOUNT_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Accounts/clientaccounts"),
    CLIENT_USER_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/ClientUser"),
    USER_ROLE_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/UserRole"),
    USER_STATUS_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser/GetWebUser/Status"),
    JOB_STATUS_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireJobStatus"),
    DOCUMENT_STATUS_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH),
    DOCUMENT_TYPE_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireDocumentTypes"),
    SEARCH_NEW_HIRE_JOB_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs"),
    MANAGE_NEW_HIRE_DOCUMENT_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument"),
    MANAGE_NEW_HIRE_DOCUMENT_TYPE_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocumentType"),
    CLIENT_GROUP_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeGroup"),
    CLIENT_DATA_TYPE_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute/attributedatatypes"),
    CLIENT_ATTRIBUTE_TYPE_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute/attributetypes"),
    MANAGE_ATTRIBUTE_GROUP_API: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeGroup"),
    MANAGE_SECTION_API: "".concat(ONBOARDING_API_BASE_PATH, "/Sections"),
    MANAGE_ATTRIBUTE_PATCH_API: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute"),
    MANAGE_ATTRIBUTE_ID_API: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute"),
    MANAGE_ATTRIBUTE_API: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute"),
    MANAGE_ATTRIBUTES_API: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute"),
    CHECK_UNIQUE_ATTR_GROUP_API: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeGroup/CheckExistingGroupName?groupName="),
    NEW_HIRE_DOCUMENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument"),
    CHECK_UNIQUE_ATTR_API: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute/CheckIfAttributeExists"),
    CHECK_VALID_FORMAT: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute/validateoptions"),
    MANAGE_ATTRIBUTE_DLT_API: "".concat(ONBOARDING_API_BASE_PATH, "/FormAttribute"),
    GET_ROLES_FEATURE_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security/GetFeaturesByApplicationID?ApplicationId"),
    GET_ROLES_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security/GetAllRoles"),
    UPDATE_FEATURE_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security/UpdateFeature"),
    SECURITY_ROLES_FEATURE_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security/GetRoleFeatures"),
    UPDATE_ROLE_FEATURES_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security/UpdateRoleFeatures?IsSecSetup=true"),
    LEFT_MENU_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security/GetRoleFeatures"),
    USER_DETAILS_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/User/GetUserDetails?Username"),
    APPROVED_JOBS_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/approved"),
    IC_WORKER_STATUS_LIST_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/ICWorker"),
    MANAGE_ATTRIBUTE_MAPPING_ON_LOAD: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeMapping/customFormAttributeMapping"),
    NEW_HIRE_DOCUMENT_CATEGORY_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocumentCategory"),
    NEW_HIRE_DOCUMENT_WORKFLOW_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocumentWorkflow"),
    DOCUMENT_TYPE: "".concat(ONBOARDING_API_BASE_PATH, "/FormDocumentTypes"),
    STATES_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/States"),
    CUSTOM_FORM_UPLOAD_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/CustomForm"),
    PUBLISH_CUSTOM_FORM_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/CustomForm/Publish"),
    CUSTOM_FORM_FILE_UPLOAD_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/CustomForm/upload"),
    WEB_USERS_LIST_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser"),
    GET_ATT_DROPDOWN_LIST_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeMapping"),
    MANAGE_ATTRIBUTE_MAPPING_DLT_API: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeMapping/removeattributemapping"),
    POST_NEW_MAPPING_API: "".concat(ONBOARDING_API_BASE_PATH, "/AttributeMapping"),
    ACRO_FIELD_MAPPING_DATA_API: "".concat(ONBOARDING_API_BASE_PATH, "/SystemFieldMapping/acroFieldMappingData"),
    DEFAULT_SYSTEM_FIELD_ATTRIBUTE_API: "".concat(ONBOARDING_API_BASE_PATH, "/SystemFieldMapping/customFormAttributeFields"),
    SAVE_SYSTEM_FIELDS_API: "".concat(ONBOARDING_API_BASE_PATH, "/SystemFieldMapping"),
    NEW_HIRE_DOC_JOB_MANAGEMENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireDocsForJobManagement"),
    DOCUMENT_BY_KEY_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Documents/getUrlBykey"),
    ADD_DOCUMENT_TO_JOB_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocumentType/AddDocument"),
    CUSTOM_SEARCH: "".concat(ONBOARDING_API_BASE_PATH, "/CustomForm/searchcustomforms"),
    MANUALLY_UPLOAD_DOCUMENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/saveDocument"),
    CANCEL_NEW_HIRE_DOCUMENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/cancel"),
    APPROVE_NEWHIRE_JOBS_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/ApproveNewHireJob"),
    RESEND_EMAIL_VERIFIER_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/ResendEmailVerifier"),
    IC_WORKER_FLAG_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetNewHireJobAgent"),
    SCREENING_INFO_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetScreeningInfo"),
    ADD_NEW_JOB_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs"),
    CANCEL_NEW_HIRE_JOB_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/CancelNewHireJob"),
    PAYREEL_COORDINATOR_API_LIST: "".concat(ONBOARDING_API_BASE_PATH, "/PayReelUser/PayreelCoordinators"),
    SEND_EMAIL_TEMPLATE_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Notification/SendEmailTemplate"),
    RESEND_USER_EMAIL_DETAILS_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireEmployee/GetResendCreateUserEmailDetails"),
    I9_AGENT_MAIL_DETAILS: "".concat(ONBOARDING_API_BASE_PATH, "/EmployeeAuthorizedI9Agent/GetEmployeeAuthorizedI9AgentMailDetails"),
    UPDATE_I9_AGENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/EmployeeAuthorizedI9Agent/UpdateEmpAuthI9AgentMapping"),
    ONBOARDING_REPORT_DOWNLOAD_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Report"),
    MANAGE_NEW_HIRE_DOCUMENT_STATUS: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireDocumentStatus"),
    SEARCH_NEW_HIRE_DOCUMENTS_API_LIST: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/SearchNewHireDocuments"),
    I9_REVERIFICATION_NEW_CLIENTS: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/NewClients"),
    I9_REVERIFICATION_EXISTING_CLIENTS: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/ExistingClients"),
    I9_REVERIFICATION_DELETE_CLIENT: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/DeleteClient"),
    I9_REVERIFICATION_CLIENT_LIST: "".concat(ONBOARDING_API_BASE_PATH, "/Clients/GetClientsByMasterWebUserId"),
    I9_REVERIFICATION_UPDATE_START_DATE_API: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/UpdateStartDateExistingClient"),
    I9_REVERIFICATION_UPDATE_EXISTING_CLIENT: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/UpdateExistingClient"),
    VIEW_SUPPORTING_DOCUMENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireSupportingDocumentsAsync"),
    CUSTOM_FORM_GET_ATTRIBUTE_VALIDATIONS: "".concat(ONBOARDING_API_BASE_PATH, "/CustomFormAttributeValidation"),
    ONBOARDING_SECTION_LIST_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/sections"),
    NEW_HIRE_JOB_ID_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetNewHireJobByEmpId"),
    EMPLOYEE_TASK_GRID_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireJobDocuments?newHireJobID"),
    SUPPORTING_HIRE_DOCUMENT_COUNT: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireSupportDocumentsCountAsync"),
    EXPIRED_VISA_LIST_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/ExpiredVISA"),
    GET_WEB_USER_DETAILS_BY_ID_API: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser/GetWebUser"),
    UPDATE_WEB_USER_DETAILS_API: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser/EditWebUser"),
    GET_EDIT_SAVE_ATTR_MANIPULATION_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributemanipulation"),
    DELETE_ATTR_MANIPULATION_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributemanipulation/remove"),
    GET_ATTR_MANIPULATION_DATA_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributemanipulation/manipulationdetails"),
    GET_ATTR_OPERATOR_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributeoperator"),
    GET_ATTR_EVENT_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributeeventtype"),
    GET_ATTR_ACTION_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributeaction"),
    MARK_SCREENING_COMPLETE_API: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/MarkScreeningComplete"),
    I9_REVERIFICATION_APPROED_LIST: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/GetApprovals"),
    I9_REVERIFICATION_APPROVE_BTN_API: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/Approve"),
    VIEW_HR_DOCUMENTS_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/HRDocument/GetHrDocuments"),
    HR_DOCUMENT_FILE_UPLOAD_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/HRDocument/UploadHrDocument"),
    HR_DOCUMENT_FILE_SUBMIT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/HRDocument"),
    GET_WORKER_SUPERVISOR_DETAIL_API: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetWorkerSupervisorDetails"),
    DYNAMIC_FORM_RENDER_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/customformrender"),
    CREATE_WEB_USER_API: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser/CreateWebUser"),
    DECLINE_NEW_HIRE_DOCUMENT_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/DeclineNewHireDocument"),
    PAYMENT_TYPES_BY_JOB_ID_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/PaymentType/GetAllPaymentTypesByJobId"),
    VIEW_BATCHES_LIST_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/GetNewHireBatches"),
    CREATE_BATCH_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/CreateBatch"),
    EMPLOYEE_DETAILS_API: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser/GetWebUser?webUserId"),
    UPDATE_EMPLOYEE_DETAILS_API: "".concat(ONBOARDING_API_BASE_PATH, "/WebUser/UpdateProfile?webUserID"),
    DIRECT_DEPOSIT_AUTH_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireEmployee/employeeinfo"),
    DIRECT_DEPOSIT_FILE_UPLOAD_API: "".concat(ONBOARDING_API_BASE_PATH, "/directdeposit/upload"),
    DIRECT_DEPOSIT_SIGN_SAVE_API: "".concat(ONBOARDING_API_BASE_PATH, "/directdeposit"),
    GET_NEW_HIRE_BY_JOB_STATUS_API: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetNewHireJobsByStatus"),
    GET_EMPLOYEE_INFORMATION_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireEmployee/employeeinfo"),
    CHANGE_I9_VERIFIER_DETAILS_API: "".concat(ONBOARDING_API_BASE_PATH, "/EmployeeAuthorizedI9Agent/ChangeI9Verifier"),
    GET_EMPLOYER_POSTER_DOCUMENT_DATA: "".concat(ONBOARDING_API_BASE_PATH, "/HRDocument/GetEmployerPosterDocumentData"),
    UPDATE_PAYMENT_TYPES_DOCUMENTS_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/UpdatePaymentTypeForDocuments"),
    GET_ADMIN_PAYREEL_TASK: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetAdminPayReelTasks"),
    COMPLETED_NEW_HIRE_DOCS_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetCompletedNewHireDocuments?newHireJobID"),
    UPDATED_NEW_HIRE_DOCUMENT_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/DownloadCompletedDocuments?applicationID"),
    GET_CLIENT_FORMS_TO_COMPLETE_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireDocumentsForClientUser"),
    GET_EMPLOYEE_WITH_INCOMPLETE_PAPERWORK_API: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetNewHireJobsIncompleteByClient"),
    GET_INCOMPLETE_EMPLOYEE_AGREEMENT_API: "".concat(ONBOARDING_API_BASE_PATH, "/Jobs/GetPendingTempAgreements?clientUserID="),
    VIEW_FORM_TEMPLATE_API_LIST: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireDocumentTemplates"),
    ONBOARDING_HR_DOCUMENTS_API: "".concat(ONBOARDING_API_BASE_PATH, "/HRDocument/GetHrDocuments"),
    SAVE_TEMP_AGREEMENTS_API: "".concat(ONBOARDING_API_BASE_PATH, "/TempAgreements/SaveTempAgreements"),
    NEW_HIRE_DOCUMENT_EXTERNAL_AGENT: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetNewHireDocumentsForExternalAgent"),
    FEDRAL_I9_SIGN_AND_SAVE: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/FedralI9SignAndSave"),
    GET_TEMP_AGREEMENT_INFO_API: "".concat(ONBOARDING_API_BASE_PATH, "/TempAgreements/GetOnboardingTempAgreementInfo"),
    GET_RESEND_EMPLOYEE_EMAIL_DETAILS_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireEmployee/GetResendEmployeeOnboardingMailDetails"),
    EMPLOYEE_INFORMATION_API: "".concat(ONBOARDING_API_BASE_PATH, "/Employee"),
    SAVE_EMPLOYEE_INFO_TEMP: "".concat(ONBOARDING_API_BASE_PATH, "/Employee/Save"),
    GENERATE_EMPLOYEE_DATA_PDF: "".concat(ONBOARDING_API_BASE_PATH, "/Employee/GeneratePdf"),
    UPLOAD_VISA_DOCUMENT: "".concat(ONBOARDING_API_BASE_PATH, "/Employee/UploadVisaDocument"),
    SIGN_SUBMIT_EMPLOYEE_INFO: "".concat(ONBOARDING_API_BASE_PATH, "/Employee/SignAndSubmit"),
    GET_ATTR_ACTION_VALUE_API: "".concat(ONBOARDING_API_BASE_PATH, "/attributeactionvalue"),
    FEDRAL_I9_LIST_DATA: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/FedralI9ListData"),
    SAVE_I9_FEDRAL_PROFILE_PIC: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/SaveI9ProfilePic"),
    DELETE_SUPPORTING_DOCUMENTS: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireSupportingDocuments/DeleteSupportingDocument?newHireSupportingDocumentID="),
    SHOW_EMPLOYEE_DOCUMENTS_COMPLETED: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/ShowEmployeeCompleteDocument"),
    NewYork_MINOR_TRUST_FILE_UPLOAD: "".concat(ONBOARDING_API_BASE_PATH, "/NewYorkMinorTrustAccount/upload"),
    NewYork_MINOR_TRUST_SIGN_SAVE: "".concat(ONBOARDING_API_BASE_PATH, "/NewYorkMinorTrustAccount"),
    CALIFORNIA_WORK_PERMIT_FILE_UPLOAD: "".concat(ONBOARDING_API_BASE_PATH, "/caminorworkpermit/upload"),
    NEW_YORK_WORK_PERMIT_FILE_UPLOAD: "".concat(ONBOARDING_API_BASE_PATH, "/nyminorworkpermit/upload"),
    CALIFORNIA_MINOR_TRUST_FILE_UPLOAD: "".concat(ONBOARDING_API_BASE_PATH, "/CaliforniaMinorTrustAccount/upload"),
    CALIFORNIA_MINOR_TRUST_SIGN_SAVE: "".concat(ONBOARDING_API_BASE_PATH, "/CaliforniaMinorTrustAccount"),
    PAYMENT_DROPDOWN_ENABLE_CHECK_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/EnablePaymentDropDown?newHireJobID="),
    NEWHIRE_BATCH_DETAILS_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/GetNewHireBatchDetails?newHireBatchID="),
    EMPLOYEE_DOC_PDF_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetEmployeeDocPdf?newHireJobID="),
    FEDRAL_I9_ADMIN_AGENT_SAVE: "".concat(ONBOARDING_API_BASE_PATH, "/I9Reverification/FedralI9AdminAgentSaveSign"),
    ADMIN_AGENT_TASKS_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/GetAdminAgentTasks"),
    THINKWARE_EMPLOYEE_DOCS_DETAILS: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/GetThinkwareViewDocumentDetails?thinkwareJobTrackingDetailID="),
    GET_THINKWARE_EMPLOYEE_LIST_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/GetThinkwareJobTrackingDetails"),
    BULK_IMPORT_JOBS_API: "".concat(ONBOARDING_API_BASE_PATH, "/BulkImportJobs/BulkJobsImport"),
    SEND_EMAIL_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Notification/SendEmail"),
    RESEND_THINKWARE_FILES_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/ResendThinkwareFiles"),
    RECREATE_THINKWARE_FILES_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireBatch/RecreateBatchExportFiles"),
    EMPLOYEE_WORK_VISA_APPROVE_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/ApproveWorkVisa"),
    EMPLOYEE_WORK_VISA_NOT_APPROVE_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/DeclineWorkVisa"),
    WORK_VISA_DOCUMENT_INFO_API: "".concat(ONBOARDING_API_BASE_PATH, "/NewHireDocument/newHireDocumentFileInfo?newHireDocumentID="),
    GEORGIA_FORM_POST_API: "".concat(ONBOARDING_API_BASE_PATH, "/GeorgiaWfour"),
    NY_EMP_NOTICE_EMPT_API: "".concat(ONBOARDING_API_BASE_PATH, "/nyemployeenoticeexempt"),
    NY_DAY_RATE_EMP_API: "".concat(ONBOARDING_API_BASE_PATH, "/nydayratenotice"),
    CA_NOTICE_TO_EMP_API: "".concat(ONBOARDING_API_BASE_PATH, "/calnoticetoemployee"),
    CORP_TIMESHEET_API_PATH: "".concat(CORP_API_BASE_PATH, "/TimeSheet"),
    CONTENT_TV_REL_API: "".concat(ONBOARDING_API_BASE_PATH, "/ContentTVRelease"),
    CORP_TAX_STATEMENT_API_PATH: "".concat(CORP_API_BASE_PATH, "/EmployeeTaxStatement"),
    CORP_PAY_STUBS_API_PATH: "".concat(CORP_API_BASE_PATH, "/PayStatement"),
    CORP_DOCUMENT_API_PATH: "".concat(CORP_API_BASE_PATH, "/Documents"),
    CORP_EXPENSE_REPORTS_API_PATH: "".concat(CORP_API_BASE_PATH, "/ExpenseReports"),
    SEXUAL_HARASSMENT_CERT_API: "".concat(ONBOARDING_API_BASE_PATH, "/sexualharassmentcertificate"),
    NY_EMP_NOTICE_HOURLY_RATE: "".concat(ONBOARDING_API_BASE_PATH, "/nyhourlyratenotice"),
    END_HOURLY_NON_EX_EMP_AGR_CLIENT_GET_POST_API: "".concat(ONBOARDING_API_BASE_PATH, "/endnonexeempagreement"),
    VERIFY_FIRST_BG_CHECK_API: "".concat(ONBOARDING_API_BASE_PATH, "/verifyfirstbgcheck"),
    VERIFY_FIRST_BG_FORM_POST_API: "".concat(ONBOARDING_API_BASE_PATH, "/verifyfirstbgcheck"),
    NY_EMP_TO_NOTICE_SALARY_API: "".concat(ONBOARDING_API_BASE_PATH, "/nyempnoticesalary"),
    MINNESOTA_WAGE_THEFT_GET_POST_API: "".concat(ONBOARDING_API_BASE_PATH, "/minwagetheft"),
    END_ONLOC_EX_EMP_AGR_API: "".concat(ONBOARDING_API_BASE_PATH, "/endloceavorexemptemployeeagreement"),
    EMPLOYEE_PROFILE_API: "".concat(CORP_API_BASE_PATH, "/Profile"),
    END_EXEMPT_EMPL_AGR_API: "".concat(ONBOARDING_API_BASE_PATH, "/endexeempagreement"),
    END_LOC_NON_EXM_AGR_API: "".concat(ONBOARDING_API_BASE_PATH, "/endlocnonexemptemployeeagreement"),
    GET_PAID_SICK_LEAVE_API_PATH: "".concat(CORP_API_BASE_PATH, "/Employee/PaidSickLeaveDetails"),
    GET_CREDIT_CARD_TRANSACTION_LIST_API_PATH: "".concat(CORP_API_BASE_PATH, "/Employee/GetCreditCardItems"),
    END_NON_EX_DL_EMP_AGR_API: "".concat(ONBOARDING_API_BASE_PATH, "/endnonexedailyempagreement"),
    REPORT_API_PATH: "".concat(CORP_API_BASE_PATH, "/Report"),
    VENDOR_PAYMENT_API_PATH: "".concat(CORP_API_BASE_PATH, "/VendorPayment"),
    CORP_TIMESHEET_DAY_DETAILS_API_PATH: "".concat(CORP_API_BASE_PATH, "/TimeSheetDayDetail"),
    CLOCKMGMT_TIMESHEET_DAY_DETAILS_API_PATH: "".concat(CORP_API_BASE_PATH, "/TimeClockManagement"),
    CLIENT_POSITION_BASE_API: "".concat(CORP_API_BASE_PATH, "/Position"),
    GET_CLIENT_USER_API_PATH: "".concat(CORP_API_BASE_PATH, "/ClientUser"),
    GET_CITY_API_PATH: "".concat(CORP_API_BASE_PATH, "/City"),
    GET_ZIPCODE_API_PATH: "".concat(CORP_API_BASE_PATH, "/ZipCode"),
    GET_WORK_LOCATION_API_PATH: "".concat(CORP_API_BASE_PATH, "/WorkLocation"),
    CORP_STATE_API_PATH: "".concat(CORP_API_BASE_PATH, "/State"),
    CORP_REQUISITION_NUMBER_API: "".concat(CORP_API_BASE_PATH, "/RequisitionNumber"),
    CORP_EMPLOYEE_API_PATH: "".concat(CORP_API_BASE_PATH, "/Employee"),
    CORP_CLOCKMGMT_API_PATH: "".concat(CORP_API_BASE_PATH, "/TimeClockManagement"),
    CORP_EMP_CREDIT_CARD_RECEIPT_API_PATH: "".concat(CORP_API_BASE_PATH, "/CreditCardReceipt"),
    MISSISSIPPI_WFOUR_API: "".concat(ONBOARDING_API_BASE_PATH, "/mississippiwfour"),
    GET_TIME_SHEET_STATUS_API: "".concat(CORP_API_BASE_PATH, "/TimeSheet/GetTimeSheetStatuses"),
    DOCUMENT_BY_KEY_API_CORP_PATH: "".concat(CORP_API_BASE_PATH, "/Documents/getUrlBykey"),
    CORP_EMP_EXPENSE_RECEIPT_FILE_API_PATH: "".concat(CORP_API_BASE_PATH, "/ExpenseReportFile"),
    VENDOR_DETAILS_PAYMENT_API: "".concat(CORP_API_BASE_PATH, "/VendorPaymentDetail"),
    CORP_REPORT_DOWNLOAD_API_PATH: "".concat(CORP_API_BASE_PATH, "/Report"),
    VENDOR_PROFILE_API: "".concat(CORP_API_BASE_PATH, "/VendorProfile"),
    CLIENT_DIVISION_LIST_API: "".concat(CORP_API_BASE_PATH, "/Account"),
    CLIENT_SEARCH_EMPLOYEE_LIST_API: "".concat(CORP_API_BASE_PATH, "/Employee/SearchEmployees?"),
    VENDOR_PAYMENT_FILE_API_PATH: "".concat(CORP_API_BASE_PATH, "/VendorPaymentFile"),
    VENDOR_DOCUMENT_API_PATH: "".concat(CORP_API_BASE_PATH, "/VendorDocument"),
    CORP_TIMESHEET_VZ_API_PATH: "".concat(CORP_API_BASE_PATH, "/TimeSheetVzDay"),
    CLIENT_WORKERS_API: "".concat(CORP_API_BASE_PATH, "/Worker"),
    SEARCH_VENDORS_API_PATH: "".concat(CORP_API_BASE_PATH, "/Vendor"),
    CORP_SUB_ACCOUNTS_API_PATH: "".concat(CORP_API_BASE_PATH, "/SubAccount"),
    CLIENT_PROJECTS_API: "".concat(CORP_API_BASE_PATH, "/SubAccount"),
    COUNTY_API_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/County"),
    GET_CLIENT_USER_DETAILS_PATH: "".concat(ONBOARDING_API_BASE_PATH, "/ClientUser"),
    MANUAL_PAYMENT_API_PATH: "".concat(CORP_API_BASE_PATH, "/ManualPayments"),
    UPLOAD_PROJECT_FILE_API_PATH: "".concat(CORP_API_BASE_PATH, "/ProjectTracking"),
    INVOICE_LAYOUT_STATUS: "".concat(CORP_API_BASE_PATH, "/InvoiceLayoutStatus"),
    INVOICE_LAYOUT_TYPE: "".concat(CORP_API_BASE_PATH, "/InvoiceLayoutType"),
    INVOICE_LAYOUT_API: "".concat(CORP_API_BASE_PATH, "/InvoiceLayout"),
    CORP_ADMIN_DASHBOARD_MESSAGE_API: "".concat(CORP_API_BASE_PATH, "/Admin/Messages"),
    CORP_ADMIN_DASHBOARD_API: "".concat(CORP_API_BASE_PATH, "/Admin"),
    EXPENSE_REPORT_STATUS_HISTORY_API: "".concat(CORP_API_BASE_PATH, "/ExpenseReportStatusHistory/GetExpenseReportStatusHistory"),
    CLOSED_AREXPORT_DRODOWN_API: "".concat(CORP_API_BASE_PATH, "/MasArExport/GetARExport"),
    INVOICING_MISC_CHARGE_API: "".concat(CORP_API_BASE_PATH, "/InvoicingMiscCharge"),
    AREXPORT_DRODOWN_API: "".concat(CORP_API_BASE_PATH, "/InvoiceHistory/GetARExport"),
    VOID_INVOICE_API_PATH: "".concat(CORP_API_BASE_PATH, "/VoidInvoice"),
    GET_INVOICE_STATUS_API: "".concat(CORP_API_BASE_PATH, "/InvoiceStatus"),
    GET_INVOICE_HISTORY_API: "".concat(CORP_API_BASE_PATH, "/InvoiceHistory"),
    CORP_TIMECARDPUNCHDATA_API_PATH: "".concat(CORP_API_BASE_PATH, "/TimeSheetPunchData"),
    GET_CLIENT_INVOICING_API_PATH: "".concat(CORP_API_BASE_PATH, "/ClientInvoicingDetail"),
    INVOICE_LAYOUT_UPLOAD_API_PATH: "".concat(CORP_API_BASE_PATH, "/InvoiceHistory"),
    GET_INVOICELAYOUT_BY_ID: "".concat(CORP_API_BASE_PATH, "/InvoiceLayout"),
    GET_AR_EXPORT_API_PATH: "".concat(CORP_API_BASE_PATH, "/MasArExport"),
    INVOICE_MAPPING_FIELDS_API: "".concat(CORP_API_BASE_PATH, "/InvoiceMappingFields"),
    CREDIT_CARD_DETAILS_API_PATH: "".concat(CORP_API_BASE_PATH, "/CreditCard"),
    EMPLOYEE_REPEAT_I9_API_PATH: "".concat(CORP_API_BASE_PATH, "/EmployeeRepeatI"),
    NON_WORKED_HOURS_CODES_BASE_API: "".concat(CORP_API_BASE_PATH, "/NonWorkedHoursCode"),
    MASTER_CLIENT_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/MasterClient"),
    GLOBAL_SETTINGS_BASE_API_PATH: "".concat(CORP_API_BASE_PATH, "/GlobalSettings"),
    MASTER_CLIENT_USER_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/MasterClientUser"),
    EXPENSE_CODE_BASE_API: "".concat(CORP_API_BASE_PATH, "/ExpenseCode"),
    GET_COUNTRIES_API_PATH: "".concat(CORP_API_BASE_PATH, "/Country/GetCountries"),
    PAID_SICK_LEAVE_BASE_API_PATH: "".concat(CORP_API_BASE_PATH, "/PaidSickLeave"),
    GET_BATCH_API_PATH: "".concat(CORP_API_BASE_PATH, "/Batch"),
    CORP_PAYREEL_USERS_BASE_API_PATH: "".concat(CORP_API_BASE_PATH, "/PayReelUser"),
    PAID_SICK_LEAVE_API_PATH: "".concat(CORP_API_BASE_PATH, "/PaidSickLeave"),
    NEW_HIRE_DOCUMENT_TYPE_CORP_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/NewHireDocumentType"),
    STATE_COUNTRY_PER_DIEM_LEVEL_DATA_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/StateCountyPerDiemLevelData"),
    GENERAL_LIABILITY_BASE_RATE_API_PATH: "".concat(CORP_API_BASE_PATH, "/GeneralLiabilityBaseRate"),
    WORKERS_COMP_JOB_TYPE_API_PATH: "".concat(CORP_API_BASE_PATH, "/WorkersCompJobTypes"),
    MINIMUM_WAGE_RATE_API_PATH: "".concat(CORP_API_BASE_PATH, "/MinimumWageRate"),
    ACA_HEALTH_INSURANCE_RATE_BASE_API: "".concat(CORP_API_BASE_PATH, "/ACAHealthInsuranceType"),
    CORP_WORK_ORDERS_API_PATH: "".concat(CORP_API_BASE_PATH, "/QVCWorkOrder"),
    CORP_WORK_ORDER_DETAIL_API_PATH: "".concat(CORP_API_BASE_PATH, "/QVCWorkOrderDetail"),
    CORP_APP_CONFIGURATION_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/AppConfiguration"),
    ACCOUNT_EMPLOYEE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/AccountEmployee"),
    CORP_CLIENT_EXPENSE_RATE_BASE_API: "".concat(CORP_API_BASE_PATH, "/ClientExpenseRate"),
    CORP_CLIENT_EQUIPMENT_RATE_BASE_API: "".concat(CORP_API_BASE_PATH, "/ClientEquipmentRate"),
    WORKERS_COMP_CODE_API_PATH: "".concat(CORP_API_BASE_PATH, "/WorkersCompCode"),
    EARNING_CODE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/ManualPaymentEarningCode"),
    CLIENT_ADMIN_RATE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/ClientAdminRate"),
    CLIENT_ADMIN_VENDOR_PAYMENT_RATE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/ClientVendorPaymentRate"),
    CLIENT_ADMIN_SUTA_MARKUP_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/ClientSutaMarkup"),
    CLIENT_ADMIN_GENERAL_LIABILITY_MARKUP_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/ClientGeneralLiabilityMarkup"),
    ADMIN_FICA_RATE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/FederalFICARate"),
    ADMIN_FUTA_RATE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/FutaBaseRate"),
    ADMIN_SUTA_RATE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/SutaBaseRate"),
    CORP_INVOICING_API_PATH: "".concat(CORP_API_BASE_PATH, "/Invoicing"),
    CORP_CLIENT_BASE_API_PATH: "".concat(CORP_API_BASE_PATH, "/Client"),
    SECURITY_BASE_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security"),
    ADMIN_WC_RATE_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/WCBaseRate"),
    CORP_SECURITY_BASE_API_PATH: "".concat(CORE_WEB_API_BASE_PATH, "/Security"),
    CLIENT_ADMIN_WC_MARKUP_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/ClientWorkersCompMarkup"),
    NEW_HIRE_JOB_CORP_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/NewHireJob"),
    PAYREEL_LOGOS_API_PATH: "".concat(CORP_API_BASE_PATH, "/PayReelLogo"),
    FEDERAL_UNEMPLOYMENT_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/FederalUnemployment"),
    TAXABLE_WAGES_API_BASE_PATH: "".concat(CORP_API_BASE_PATH, "/TaxableBaseWages"),
    SOCIAL_SECURITY_WAGE_CAP: "".concat(CORP_API_BASE_PATH, "/SocialSecurityWagesCap"),
    INVOICE_DELIVERY_SETTING_API_PATH: "".concat(CORP_API_BASE_PATH, "/InvoiceDeliverySetting"),
    INVOICE_DETAIL_REPORT_API_PATH: "".concat(CORP_API_BASE_PATH, "/InvoiceDetailReport"),
    INVOICE_EMAIL_CATEGORY_TYPE: "".concat(CORP_API_BASE_PATH, "/InvoiceEmailCategoryType"),
    PROJECTS_API_PATH: "".concat(CORP_API_BASE_PATH, "/project"),
    PROJECTS_WORKERS_API_PATH: "".concat(CORP_API_BASE_PATH, "/ProjectWorker"),
    EXEMPT_CLASSIFICATION_API_PATH: "".concat(CORP_API_BASE_PATH, "/exemptclassification"),
    PREPAID_EXPENSE_API_PATH: "".concat(CORP_API_BASE_PATH, "/prepaidexpense"),
};
