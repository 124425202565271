var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { commaSepratedNumberWithStyle, maskPhoneNumber } from "./utils";
import { formatDate } from "@progress/kendo-intl";
export var ActionCell = function (_a) {
    var cellprops = _a.cellprops, title = _a.title, onClick = _a.onClick, _b = _a.icon, icon = _b === void 0 ? "" : _b, _c = _a.isHidden, isHidden = _c === void 0 ? false : _c, _d = _a.showTitleIfHidden, showTitleIfHidden = _d === void 0 ? false : _d, _e = _a.additionalText, additionalText = _e === void 0 ? "" : _e, _f = _a.classNameValue, classNameValue = _f === void 0 ? "" : _f;
    return (_jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected, className: classNameValue }, { children: isHidden ? (showTitleIfHidden ? (title) : ("")) : (_jsxs(_Fragment, { children: [_jsx("span", { children: additionalText }), _jsxs("a", __assign({ role: "button", "data-testid": "".concat(title, "-button"), onClick: function () { return onClick(cellprops); } }, { children: [icon && _jsx("i", { className: icon }), title] }))] })) })));
};
export var FooterCellWithCurrency = function (_a) {
    var _b = _a.field, field = _b === void 0 ? "" : _b, _c = _a.colSpan, colSpan = _c === void 0 ? 0 : _c, _d = _a.cellTitle, cellTitle = _d === void 0 ? null : _d, data = _a.data, restProps = __rest(_a, ["field", "colSpan", "cellTitle", "data"]);
    var total = data.reduce(function (acc, current) { return parseFloat(acc) + parseFloat(current[field] || 0); }, 0.0);
    if (cellTitle) {
        return (_jsxs("td", __assign({}, restProps, { colSpan: 3 }, { children: [cellTitle, ": $", parseFloat(total).toFixed(2)] })));
    }
    else {
        return _jsxs("td", __assign({}, restProps, { children: ["$", parseFloat(total).toFixed(2)] }));
    }
};
export var FooterCellWithCustomValue = function (_a) {
    var _b = _a.colSpan, colSpan = _b === void 0 ? 0 : _b, cellTotal = _a.cellTotal, _c = _a.cellTitle, cellTitle = _c === void 0 ? null : _c, restProps = __rest(_a, ["colSpan", "cellTotal", "cellTitle"]);
    return (_jsxs("td", __assign({}, restProps, { colSpan: colSpan }, { children: [cellTitle ? "".concat(cellTitle, ":") : "", " $", parseFloat(cellTotal).toFixed(2)] })));
};
export var FooterCellTotalCount = function (_a) {
    var _b = _a.field, field = _b === void 0 ? "" : _b, data = _a.data;
    var total = data.reduce(function (acc, current) { return parseFloat(acc) + parseFloat(current[field] || 0); }, 0.0);
    return _jsx("td", { children: commaSepratedNumberWithStyle(total) });
};
export var ExpandCell = function (_a) {
    var dataItem = _a.dataItem, expandChange = _a.expandChange;
    return (_jsx("td", __assign({ className: "k-hierarchy-cell", width: "25px" }, { children: _jsx("a", { onClick: function () { return expandChange(dataItem); }, className: dataItem.expanded ? "k-icon k-minus" : "k-icon k-plus", role: "button" }) })));
};
export var ExpandCellWithText = function (_a) {
    var dataItem = _a.dataItem, expandChange = _a.expandChange, title = _a.title;
    return (_jsx("td", __assign({ className: "k-hierarchy-cell text-left" }, { children: _jsx("a", __assign({ onClick: function () { return expandChange(dataItem); }, className: "ms-3", role: "button" }, { children: title })) })));
};
export var CapitalizeCell = function (_a) {
    var cellprops = _a.cellprops, dispalyText = _a.dispalyText;
    return (_jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected, style: {
            textTransform: "capitalize",
        } }, { children: dispalyText })));
};
export var TypeTimeShetCell = function (cellprops) {
    return (_jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: cellprops.dataItem.isMobile ? (_jsx("div", __assign({ className: "typeTimeSheetCell-iconContainer" }, { children: _jsx("i", { className: "fa fa-mobile typeTimeSheetCell-mobileIcon", "aria-hidden": "true", title: "Mobile" }) }))) : null })));
};
export var HeaderCellTooltip = function (props) {
    return (_jsxs("a", __assign({ className: "k-link", onClick: props.onClick }, { children: [_jsx("span", __assign({ title: props.title }, { children: props.title })), props.children] })));
};
export var RenderSameCellValues = function (_a) {
    var cellprops = _a.cellprops, displayValue = _a.displayValue;
    return (_jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: displayValue })));
};
export var FormatDateCell = function (_a) {
    var cellProps = _a.cellProps;
    var _b = cellProps.formatType, formatType = _b === void 0 ? "MM/dd/yy" : _b, dataItem = cellProps.dataItem, field = cellProps.field, restProps = __rest(cellProps, ["formatType", "dataItem", "field"]);
    var displayFormatedDate = dataItem[field]
        ? formatDate(new Date(dataItem[field]), formatType)
        : "";
    return _jsx("td", __assign({}, restProps, { children: displayFormatedDate }));
};
import { DatePicker } from "@progress/kendo-react-dateinputs";
export var DateFilterCell = function (props) {
    var handleClear = function (e) {
        props.onChange({
            value: null,
            operator: "",
            syntheticEvent: e,
        });
    };
    var handleChange = function (e) {
        var value = e.value;
        if (value) {
            props.onChange({
                value: new Date(e.value),
                operator: "eq",
                format: "MM/dd/yy",
                syntheticEvent: e.syntheticEvent,
            });
        }
        else {
            handleClear(e);
        }
    };
    return (_jsx("div", __assign({ className: "k-filtercell" }, { children: _jsxs("div", __assign({ className: "k-filtercell-wrapper" }, { children: [_jsx(DatePicker, { placeholder: "MM/DD/YY", format: "MM/dd/yy", value: props.value, formatPlaceholder: {
                        year: "YY",
                        month: "MM",
                        day: "DD",
                    }, onChange: handleChange }), _jsx("div", __assign({ className: "k-filtercell-operator" }, { children: _jsx("button", __assign({ title: "Clear", type: "button", onClick: function (e) {
                            e.preventDefault();
                            handleClear(e);
                        }, className: "k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button ".concat(props.value ? "" : "k-disabled") }, { children: _jsx("span", { className: "k-icon k-i-filter-clear k-button-icon", role: "presentation" }) })) }))] })) })));
};
export var maskedPhoneNumberCell = function (cellProps) {
    var _a = cellProps.formatType, formatType = _a === void 0 ? "MM/dd/yy" : _a, dataItem = cellProps.dataItem, field = cellProps.field, restProps = __rest(cellProps, ["formatType", "dataItem", "field"]);
    return _jsx("td", __assign({}, restProps, { children: maskPhoneNumber(dataItem[field]) }));
};
