var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { filterBy } from "@progress/kendo-data-query";
import { DateInput, DatePicker, DateRangePicker, DateTimePicker, TimePicker, } from "@progress/kendo-react-dateinputs";
import { AutoComplete, ComboBox, DropDownList, MultiColumnComboBox, MultiSelect, } from "@progress/kendo-react-dropdowns";
import { Field, FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox, ColorPicker, Input, MaskedTextBox, NumericTextBox, RadioGroup, RangeSlider, Rating, Slider, SliderLabel, Switch, TextArea, } from "@progress/kendo-react-inputs";
import { LocalizationProvider, loadMessages } from "@progress/kendo-react-intl";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import { AppConstant, ComponentConstant } from "common/constant";
import * as React from "react";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { cleanedZipCode, modifiedZipCode } from "./utils";
var isPresent = function (value) { return value !== null && value !== undefined; };
var itemRender = function (li, itemProps) {
    var itemChildren = (_jsxs("span", { children: [_jsx("input", { type: "checkbox", name: itemProps.dataItem, checked: itemProps.selected, onChange: function (e) { return itemProps.onClick(itemProps.index, e); } }), "\u00A0", li.props.children] }));
    return React.cloneElement(li, li.props, itemChildren);
};
var triggerEvent = function (eventType, eventHandler, event, fieldRenderProps) {
    if (eventHandler) {
        return fieldRenderProps[eventType].apply(undefined, __spreadArray(__spreadArray([], event, true), [eventHandler(event)], false));
    }
};
export var FieldWIthMultipleValidator = function (props) {
    var validator = props.validator, name = props.name, validatorstatefield = props.validatorstatefield, component = props.component;
    var enchancedValidator = React.useCallback(function (value, valueGetter) {
        if (validatorstatefield) {
            return validator[1]();
        }
        return validator[0] && validator[0](value, valueGetter, name);
    }, [name, validatorstatefield]);
    return _jsx(Field, __assign({}, props, { name: name, component: component, validator: enchancedValidator }));
};
export var FormInput = function (fieldRenderProps) {
    var _a;
    var name = fieldRenderProps.name, validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, isFocusableElement = fieldRenderProps.isFocusableElement, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, type = fieldRenderProps.type, optional = fieldRenderProps.optional, defaultValue = fieldRenderProps.defaultValue, isRequired = fieldRenderProps.isRequired, blurHandler = fieldRenderProps.blurHandler, focusHandler = fieldRenderProps.focusHandler, isHorizontal = fieldRenderProps.isHorizontal, displayMsgOnChange = fieldRenderProps.displayMsgOnChange, showCharacterLimit = fieldRenderProps.showCharacterLimit, others = __rest(fieldRenderProps, ["name", "validationMessage", "touched", "label", "id", "isFocusableElement", "valid", "disabled", "hint", "type", "optional", "defaultValue", "isRequired", "blurHandler", "focusHandler", "isHorizontal", "displayMsgOnChange", "showCharacterLimit"]);
    var showValidationMessage = touched && validationMessage;
    if (displayMsgOnChange && !touched && others.value) {
        showValidationMessage = others.value && validationMessage;
    }
    else {
        showValidationMessage = touched && validationMessage;
    }
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var events = {
        onBlur: function (event) { return triggerEvent("onBlur", blurHandler, event, fieldRenderProps); },
        onFocus: function (event) { return triggerEvent("onFocus", focusHandler, event, fieldRenderProps); },
    };
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), editorId: id, editorValid: valid, editorDisabled: disabled, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsxs("div", __assign({ className: "k-form-field-wrap" }, { children: [_jsx(Input, __assign({ valid: valid, type: type, id: id, autoFocus: isFocusableElement, disabled: disabled, defaultValue: defaultValue, ariaDescribedBy: "".concat(hintId, " ").concat(errorId) }, others, events)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showCharacterLimit && (_jsxs(Hint, __assign({ direction: "end" }, { children: [((_a = fieldRenderProps.value) === null || _a === void 0 ? void 0 : _a.length) || 0, " / ", fieldRenderProps.maxlength] })))] }))] }));
};
export var MultiColumnCombo = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, defaultValue = fieldRenderProps.defaultValue, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, comboData = fieldRenderProps.comboData, columnNames = fieldRenderProps.columnNames, placeholder = fieldRenderProps.placeholder, style = fieldRenderProps.style, blurHandler = fieldRenderProps.blurHandler, filterable = fieldRenderProps.filterable, onFilterChange = fieldRenderProps.onFilterChange, focusHandler = fieldRenderProps.focusHandler, textField = fieldRenderProps.textField, allowCustom = fieldRenderProps.allowCustom, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "defaultValue", "hint", "wrapperStyle", "isHorizontal", "comboData", "columnNames", "placeholder", "style", "blurHandler", "filterable", "onFilterChange", "focusHandler", "textField", "allowCustom"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    var events = {
        onBlur: function (event) { return triggerEvent("onBlur", blurHandler, event, fieldRenderProps); },
        onFocus: function (event) { return triggerEvent("onFocus", focusHandler, event, fieldRenderProps); },
    };
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(MultiColumnComboBox, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, data: comboData, columns: columnNames, textField: textField, id: id, defaultValue: defaultValue, allowCustom: allowCustom, filterable: filterable, onFilterChange: onFilterChange, style: style, placeholder: placeholder }, others, events)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })));
};
export var FormNumericTextBox = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, isRequired = fieldRenderProps.isRequired, disableWheel = fieldRenderProps.disableWheel, blurHandler = fieldRenderProps.blurHandler, focusHandler = fieldRenderProps.focusHandler, isHorizontal = fieldRenderProps.isHorizontal, restrictNegative = fieldRenderProps.restrictNegative, displayMsgOnChange = fieldRenderProps.displayMsgOnChange, customClass = fieldRenderProps.customClass, defaultValue = fieldRenderProps.defaultValue, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "isRequired", "disableWheel", "blurHandler", "focusHandler", "isHorizontal", "restrictNegative", "displayMsgOnChange", "customClass", "defaultValue"]);
    var showValidationMessage = touched && validationMessage;
    if (displayMsgOnChange && others.value && !touched) {
        showValidationMessage = others.value >= 0 && validationMessage;
    }
    else {
        showValidationMessage = touched && validationMessage;
    }
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var ref = React.useRef(null);
    var events = {
        onBlur: function (event) { return triggerEvent("onBlur", blurHandler, event, fieldRenderProps); },
        onFocus: function (event) { return triggerEvent("onFocus", focusHandler, event, fieldRenderProps); },
    };
    var handleOnWheel = function (event) {
        event.stopPropagation();
    };
    var handleKeydown = function (event) {
        if (event.keyCode === 189) {
            event.stopPropagation();
            event.preventDefault();
        }
    };
    var handleUpDowanArrowKey = function (event) {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.stopPropagation();
            event.preventDefault();
        }
    };
    React.useEffect(function () {
        var _a, _b, _c, _d;
        if (ref) {
            (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.addEventListener("wheel", handleOnWheel);
            (_d = (_c = ref === null || ref === void 0 ? void 0 : ref.current) === null || _c === void 0 ? void 0 : _c.element) === null || _d === void 0 ? void 0 : _d.addEventListener("keydown", handleUpDowanArrowKey);
            return function () {
                var _a, _b, _c, _d;
                (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.removeEventListener("wheel", handleOnWheel);
                (_d = (_c = ref === null || ref === void 0 ? void 0 : ref.current) === null || _c === void 0 ? void 0 : _c.element) === null || _d === void 0 ? void 0 : _d.removeEventListener("keydown", handleUpDowanArrowKey);
            };
        }
    }, []);
    React.useEffect(function () {
        var _a, _b;
        if (restrictNegative && ref) {
            (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.addEventListener("keydown", handleKeydown);
            return function () {
                var _a, _b;
                (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.removeEventListener("keydown", handleKeydown);
            };
        }
    }, [restrictNegative]);
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId, className: customClass }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId, className: customClass }, { children: validationMessage }))), _jsx(NumericTextBox, __assign({ ref: ref, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id, disabled: disabled, defaultValue: defaultValue }, others, events)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }));
};
export var FormRadioGroup = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, value = fieldRenderProps.value, id = fieldRenderProps.id, label = fieldRenderProps.label, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, visited = fieldRenderProps.visited, modified = fieldRenderProps.modified, isRequired = fieldRenderProps.isRequired, isHorizontal = fieldRenderProps.isHorizontal, noLabelRequired = fieldRenderProps.noLabelRequired, others = __rest(fieldRenderProps, ["validationMessage", "touched", "value", "id", "label", "valid", "disabled", "hint", "visited", "modified", "isRequired", "isHorizontal", "noLabelRequired"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : "", " ").concat(noLabelRequired ? "hide-label" : ""), id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(RadioGroup, __assign({ ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ariaLabelledBy: labelId, valid: valid, value: value, disabled: disabled, ref: editorRef }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }));
};
export var FormCheckbox = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, id = fieldRenderProps.id, valid = fieldRenderProps.valid, defaultChecked = fieldRenderProps.defaultChecked, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, optional = fieldRenderProps.optional, label = fieldRenderProps.label, visited = fieldRenderProps.visited, modified = fieldRenderProps.modified, isRequired = fieldRenderProps.isRequired, others = __rest(fieldRenderProps, ["validationMessage", "touched", "id", "valid", "defaultChecked", "disabled", "hint", "optional", "label", "visited", "modified", "isRequired"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, { children: [showValidationMessage && (_jsx(Error, __assign({ className: "".concat(isRequired ? "required-checkbox-field" : ""), id: errorId }, { children: validationMessage }))), _jsx(Checkbox, __assign({ ariaDescribedBy: "".concat(hintId, " ").concat(errorId), label: label, labelOptional: optional, valid: valid, defaultChecked: defaultChecked, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }));
};
export var FormSwitch = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, optional = fieldRenderProps.optional, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "optional", "id", "valid", "disabled", "hint", "isHorizontal"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(Switch, __assign({ ref: editorRef, ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] }));
};
export var FormMaskedTextBox = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, hint = fieldRenderProps.hint, optional = fieldRenderProps.optional, isRequired = fieldRenderProps.isRequired, isHorizontal = fieldRenderProps.isHorizontal, blurHandler = fieldRenderProps.blurHandler, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "hint", "optional", "isRequired", "isHorizontal", "blurHandler"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var events = {
        onBlur: function (event) { return triggerEvent("onBlur", blurHandler, event, fieldRenderProps); },
    };
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), editorId: id, editorValid: valid, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsxs("div", __assign({ className: "k-form-field-wrap" }, { children: [_jsx(MaskedTextBox, __assign({ ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id }, others, events)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }))] }));
};
export var FormZipTextBox = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, hint = fieldRenderProps.hint, optional = fieldRenderProps.optional, isRequired = fieldRenderProps.isRequired, value = fieldRenderProps.value, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "hint", "optional", "isRequired", "value", "isHorizontal"]);
    var ref = React.useRef(null);
    var _a = React.useState(value), inputValue = _a[0], setInputValue = _a[1];
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    React.useEffect(function () {
        if (value) {
            var cleanedValue = cleanedZipCode(value);
            if (cleanedValue.length >= 5) {
                setInputValue(modifiedZipCode(value));
            }
            else {
                setInputValue(value);
            }
        }
    }, [value]);
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), editorId: id, editorValid: valid, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsxs("div", __assign({ className: "k-form-field-wrap" }, { children: [_jsx(MaskedTextBox, __assign({ ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id, ref: ref }, others, { value: inputValue })), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }))] }));
};
export var FormTextArea = function (fieldRenderProps) {
    var _a;
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, hint = fieldRenderProps.hint, disabled = fieldRenderProps.disabled, optional = fieldRenderProps.optional, isHorizontal = fieldRenderProps.isHorizontal, isRequired = fieldRenderProps.isRequired, showCharacterLimit = fieldRenderProps.showCharacterLimit, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "hint", "disabled", "optional", "isHorizontal", "isRequired", "showCharacterLimit"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), editorId: id, editorValid: valid, editorDisabled: disabled, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(TextArea, __assign({ valid: valid, id: id, disabled: disabled, ariaDescribedBy: "".concat(hintId, " ").concat(errorId) }, others)), showCharacterLimit && (_jsxs(Hint, __assign({ direction: "end" }, { children: [((_a = fieldRenderProps.value) === null || _a === void 0 ? void 0 : _a.length) || 0, " / ", fieldRenderProps.maxlength] })))] }));
};
export var FormColorPicker = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(ColorPicker, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })));
};
export var FormSlider = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, data = fieldRenderProps.data, min = fieldRenderProps.min, max = fieldRenderProps.max, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "data", "min", "max", "isHorizontal"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(Slider, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, id: id, disabled: disabled, min: min, max: max }, others, { children: data.map(function (value) { return (_jsx(SliderLabel, __assign({ title: value, position: value }, { children: value.toString() }), value)); }) })), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] }));
};
export var FormRangeSlider = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, hint = fieldRenderProps.hint, disabled = fieldRenderProps.disabled, data = fieldRenderProps.data, min = fieldRenderProps.min, max = fieldRenderProps.max, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "hint", "disabled", "data", "min", "max", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ editorId: id, editorValid: valid }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(RangeSlider, __assign({ valid: valid, id: id, disabled: disabled, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), min: min, max: max }, others, { children: data.map(function (value) {
                    return (_jsx(SliderLabel, __assign({ position: value }, { children: value.toString() }), value));
                }) })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] }));
};
export var FormRating = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, hint = fieldRenderProps.hint, disabled = fieldRenderProps.disabled, optional = fieldRenderProps.optional, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "hint", "disabled", "optional", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ editorId: id, editorValid: valid, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(Rating, __assign({ valid: valid, id: id, disabled: disabled, ariaDescribedBy: "".concat(hintId, " ").concat(errorId) }, others)), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] }));
};
export var FileUpload = function (fieldRenderProps) {
    var value = fieldRenderProps.value, id = fieldRenderProps.id, pathToUpload = fieldRenderProps.pathToUpload, optional = fieldRenderProps.optional, label = fieldRenderProps.label, hint = fieldRenderProps.hint, validationMessage = fieldRenderProps.validationMessage, valid = fieldRenderProps.valid, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, multiple = fieldRenderProps.multiple, autoUpload = fieldRenderProps.autoUpload, save = fieldRenderProps.save, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["value", "id", "pathToUpload", "optional", "label", "hint", "validationMessage", "valid", "isRequired", "touched", "multiple", "autoUpload", "save", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    var setShowProgress = useCurrentViewContext().setShowProgress;
    var onChangeHandler = function (event) {
        if (event.newState[0].validationErrors && event.newState[0].validationErrors.length) {
            setShowProgress(false);
            if (fieldRenderProps.onUploadFailureOrError)
                fieldRenderProps.onUploadFailureOrError(event);
        }
        else {
            setShowProgress(true);
        }
        if (fieldRenderProps.onFileAdd) {
            fieldRenderProps.onFileAdd(event);
        }
        fieldRenderProps.onChange({ value: event.newState });
    };
    var onRemoveHandler = function (event) {
        fieldRenderProps.onChange({ value: event.newState });
    };
    var onStatusChange = function (event) {
        fieldRenderProps.onChange({ value: event.newState });
        if (fieldRenderProps.onUploadCompleteHandler) {
            fieldRenderProps.onUploadCompleteHandler(event);
        }
        else {
            setShowProgress(false);
        }
    };
    return (_jsxs(FieldWrapper, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorValid: valid, editorId: id, optional: optional }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(Upload, __assign({ id: id, autoUpload: autoUpload, showActionButtons: false, multiple: multiple, files: value, saveUrl: pathToUpload, onAdd: onChangeHandler, onRemove: onRemoveHandler, onStatusChange: onStatusChange, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ariaLabelledBy: labelId, disabled: useCurrentViewContext.getState().userAccessType ===
                    AppConstant.READ_ONLY_USER_ACCESS_VALUE }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }));
};
export var FormDropDownList = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(DropDownList, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] })));
};
var DropDownListWithValueField = withValueField(DropDownList);
export var FormDropDownListWithValue = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, labelHidden = fieldRenderProps.labelHidden, blurHandler = fieldRenderProps.blurHandler, focusHandler = fieldRenderProps.focusHandler, data = fieldRenderProps.data, isFilterableDropdown = fieldRenderProps.isFilterableDropdown, others = __rest(fieldRenderProps, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal", "labelHidden", "blurHandler", "focusHandler", "data", "isFilterableDropdown"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    var events = {
        onBlur: function (event) { return triggerEvent("onBlur", blurHandler, event, fieldRenderProps); },
        onFocus: function (event) { return triggerEvent("onFocus", focusHandler, event, fieldRenderProps); },
    };
    var _a = React.useState(data === null || data === void 0 ? void 0 : data.slice()), dropDownList = _a[0], setDropDownListData = _a[1];
    React.useEffect(function () {
        setDropDownListData(data === null || data === void 0 ? void 0 : data.slice());
    }, [data]);
    var filterData = function (filter) {
        var filterAllListData = data === null || data === void 0 ? void 0 : data.slice();
        return filterBy(filterAllListData, filter);
    };
    var filterChange = function (event) {
        setDropDownListData(filterData(event.filter));
    };
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [!labelHidden && (_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] }))), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(DropDownListWithValueField, __assign({ ariaLabelledBy: labelId, data: dropDownList, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, filterable: true, onFilterChange: filterChange, id: id, disabled: disabled, skipDisabledItems: true, valueField: fieldRenderProps.dataField }, others, events)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] })));
};
export var FormAutoComplete = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(AutoComplete, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })));
};
export var FormComboBox = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, isRequired = fieldRenderProps.isRequired, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal", "isRequired"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(ComboBox, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })));
};
export var FormMultiSelect = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, value = fieldRenderProps.value, isHorizontal = fieldRenderProps.isHorizontal, blurHandler = fieldRenderProps.blurHandler, focusHandler = fieldRenderProps.focusHandler, data = fieldRenderProps.data, others = __rest(fieldRenderProps, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "value", "isHorizontal", "blurHandler", "focusHandler", "data"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    var events = {
        onBlur: function (event) { return triggerEvent("onBlur", blurHandler, event, fieldRenderProps); },
        onFocus: function (event) { return triggerEvent("onFocus", focusHandler, event, fieldRenderProps); },
    };
    var _a = React.useState(data === null || data === void 0 ? void 0 : data.slice()), dropDownList = _a[0], setDropDownListData = _a[1];
    React.useEffect(function () {
        setDropDownListData(data === null || data === void 0 ? void 0 : data.slice());
    }, [data]);
    var filterData = function (filter) {
        var filterAllListData = data === null || data === void 0 ? void 0 : data.slice();
        return filterBy(filterAllListData, filter);
    };
    var filterChange = function (event) {
        setDropDownListData(filterData(event.filter));
    };
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsxs("div", __assign({ className: "multi-select-dropdown-container" }, { children: [_jsx(MultiSelect, __assign({ ariaLabelledBy: labelId, itemRender: itemRender, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, value: value, autoClose: false, id: id, disabled: disabled, data: dropDownList, filterable: true, onFilterChange: filterChange }, others, events)), _jsx("span", { role: "presentation", className: "k-button-icon k-icon k-i-caret-alt-down" })] })), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] })));
};
export var FormDatePicker = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, hintDirection = fieldRenderProps.hintDirection, defaultValue = fieldRenderProps.defaultValue, isHorizontal = fieldRenderProps.isHorizontal, readonlyInput = fieldRenderProps.readonlyInput, allowClear = fieldRenderProps.allowClear, others = __rest(fieldRenderProps, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "hintDirection", "defaultValue", "isHorizontal", "readonlyInput", "allowClear"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    var ref = React.useRef(null);
    var preventDetaulMethods = function (event) {
        event.stopPropagation();
        event.preventDefault();
    };
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if ((ref === null || ref === void 0 ? void 0 : ref.current) && readonlyInput) {
            var inputEle = document.getElementById((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.props.id) || null;
            if (inputEle) {
                inputEle.disabled = true;
            }
            (_c = (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.element) === null || _c === void 0 ? void 0 : _c.addEventListener("wheel", preventDetaulMethods);
            (_e = (_d = ref === null || ref === void 0 ? void 0 : ref.current) === null || _d === void 0 ? void 0 : _d.element) === null || _e === void 0 ? void 0 : _e.addEventListener("keydown", preventDetaulMethods);
            (_g = (_f = ref === null || ref === void 0 ? void 0 : ref.current) === null || _f === void 0 ? void 0 : _f.element) === null || _g === void 0 ? void 0 : _g.addEventListener("keypress", preventDetaulMethods);
            return function () {
                var _a, _b, _c, _d, _e, _f;
                (_b = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.removeEventListener("wheel", preventDetaulMethods);
                (_d = (_c = ref === null || ref === void 0 ? void 0 : ref.current) === null || _c === void 0 ? void 0 : _c.element) === null || _d === void 0 ? void 0 : _d.removeEventListener("keydown", preventDetaulMethods);
                (_f = (_e = ref === null || ref === void 0 ? void 0 : ref.current) === null || _e === void 0 ? void 0 : _e.element) === null || _f === void 0 ? void 0 : _f.removeEventListener("keypress", preventDetaulMethods);
            };
        }
    }, [readonlyInput]);
    var onClear = function () { return fieldRenderProps.onChange({ value: null }); };
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsxs("div", __assign({ className: "k-form-field-wrap datepicker_container" }, { children: [_jsx(DatePicker, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, defaultValue: defaultValue, id: id, ref: ref, 
                        // show={true}
                        placeholder: "MM/DD/YYYY", format: "MM/dd/yyyy", formatPlaceholder: {
                            year: "YYYY",
                            month: "MM",
                            day: "DD",
                        }, disabled: disabled }, others)), allowClear && (_jsx("span", { role: "presentation", onClick: onClear, className: "k-button-icon k-icon k-i-clear k-clear-value" })), showHint && (_jsx(Hint, __assign({ id: hintId, direction: hintDirection }, { children: hint })))] }))] })));
};
export var FormDateTimePicker = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(DateTimePicker, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })));
};
export var FormTimePicker = function (fieldRenderProps) {
    var isRequired = fieldRenderProps.isRequired, validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["isRequired", "validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(TimePicker, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] })));
};
export var FormDateInput = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, isRequired = fieldRenderProps.isRequired, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(DateInput, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] })));
};
export var FormDateRangePicker = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, label = fieldRenderProps.label, id = fieldRenderProps.id, valid = fieldRenderProps.valid, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, wrapperStyle = fieldRenderProps.wrapperStyle, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "isHorizontal"]);
    var editorRef = React.useRef(null);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ id: labelId, editorRef: editorRef, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), _jsx(DateRangePicker, __assign({ ariaLabelledBy: labelId, ariaDescribedBy: "".concat(hintId, " ").concat(errorId), ref: editorRef, valid: valid, id: id, disabled: disabled }, others)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })));
};
export default function withValueField(DropDownComponent) {
    return /** @class */ (function (_super) {
        __extends(WithValueField, _super);
        function WithValueField() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.events = {
                onBlur: function (event) { return _this.triggerEvent("onBlur", event); },
                onFocus: function (event) { return _this.triggerEvent("onFocus", event); },
                onChange: function (event) { return _this.triggerEvent("onChange", event); },
                onPageChange: function (event) { return _this.triggerEvent("onPageChange", event); },
                onFilterChange: function (event) { return _this.triggerEvent("onFilterChange", event); },
            };
            _this.triggerEvent = function (eventType, event) {
                if (_this.props[eventType]) {
                    _this.props[eventType].call(undefined, __assign(__assign({}, event), { value: _this.value, target: _this }));
                }
            };
            return _this;
        }
        Object.defineProperty(WithValueField.prototype, "value", {
            get: function () {
                if (this.component) {
                    var value = this.component.value;
                    return isPresent(value) ? value[this.props.valueField || ""] : value;
                }
            },
            enumerable: false,
            configurable: true
        });
        WithValueField.prototype.render = function () {
            var _this = this;
            var _a = this.props, validationMessage = _a.validationMessage, isRequired = _a.isRequired, touched = _a.touched, label = _a.label, id = _a.id, valid = _a.valid, disabled = _a.disabled, hint = _a.hint, wrapperStyle = _a.wrapperStyle, showValidationMessage = _a.showValidationMessage, isHorizontal = _a.isHorizontal, others = __rest(_a, ["validationMessage", "isRequired", "touched", "label", "id", "valid", "disabled", "hint", "wrapperStyle", "showValidationMessage", "isHorizontal"]);
            var showHint = !showValidationMessage && hint;
            var hintId = showHint ? "".concat(id, "_hint") : "";
            var errorId = showValidationMessage ? "".concat(id, "_error") : "";
            var labelId = label ? "".concat(id, "_label") : "";
            return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && (_jsx(Error, __assign({ id: errorId }, { children: ComponentConstant.ERROR_FIELD_REQUIRED }))), _jsx(DropDownComponent, __assign({}, this.props, { value: this.itemFromValue(this.props.value), ref: function (dropdown) { return (_this.component = dropdown); }, valid: valid }, this.events)), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] })));
        };
        WithValueField.prototype.itemFromValue = function (value) {
            var _a = this.props, _b = _a.data, data = _b === void 0 ? [] : _b, valueField = _a.valueField;
            return isPresent(value) ? data.find(function (item) { return item[valueField || ""] === value; }) : value;
        };
        return WithValueField;
    }(React.Component));
}
export var FieldWIthMultipleDynamicValidator = function (props) {
    var validator = props.validator, name = props.name, validationRules = props.validationRules, others = __rest(props, ["validator", "name", "validationRules"]);
    var enchancedValidator = React.useCallback(function (value, valueGetter) { return validator(value, valueGetter, name, validationRules); }, [name]);
    return _jsx(Field, __assign({}, props, { name: name, validator: enchancedValidator }));
};
export var CreateUserFormCheckbox = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, id = fieldRenderProps.id, valid = fieldRenderProps.valid, defaultChecked = fieldRenderProps.defaultChecked, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, optional = fieldRenderProps.optional, label = fieldRenderProps.label, visited = fieldRenderProps.visited, modified = fieldRenderProps.modified, isRequired = fieldRenderProps.isRequired, isHorizontal = fieldRenderProps.isHorizontal, others = __rest(fieldRenderProps, ["validationMessage", "touched", "id", "valid", "defaultChecked", "disabled", "hint", "optional", "label", "visited", "modified", "isRequired", "isHorizontal"]);
    var showValidationMessage = touched && validationMessage;
    var showHint = !showValidationMessage && hint;
    var hintId = showHint ? "".concat(id, "_hint") : "";
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, { children: [_jsx(Checkbox, __assign({ ariaDescribedBy: "".concat(hintId, " ").concat(errorId), label: label, labelOptional: optional, valid: valid, defaultChecked: defaultChecked, id: id, disabled: disabled }, others)), showValidationMessage && (_jsx(Error, __assign({ className: "".concat(isRequired ? "required-checkbox-field" : ""), id: errorId }, { children: validationMessage }))), showHint && _jsx(Hint, __assign({ id: hintId }, { children: hint }))] }));
};
export var UploadInput = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, id = fieldRenderProps.id, valid = fieldRenderProps.valid, defaultChecked = fieldRenderProps.defaultChecked, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, optional = fieldRenderProps.optional, label = fieldRenderProps.label, visited = fieldRenderProps.visited, modified = fieldRenderProps.modified, isRequired = fieldRenderProps.isRequired, isHorizontal = fieldRenderProps.isHorizontal, wrapperStyle = fieldRenderProps.wrapperStyle, dropFilesHereText = fieldRenderProps.dropFilesHereText, selectText = fieldRenderProps.selectText, others = __rest(fieldRenderProps, ["validationMessage", "touched", "id", "valid", "defaultChecked", "disabled", "hint", "optional", "label", "visited", "modified", "isRequired", "isHorizontal", "wrapperStyle", "dropFilesHereText", "selectText"]);
    var showValidationMessage = touched && validationMessage;
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    var onChangeHandler = function (event) {
        fieldRenderProps.onChange({ value: event.newState });
    };
    var onRemoveHandler = function (event) {
        fieldRenderProps.onChange({ value: event.newState });
    };
    loadMessages({
        upload: {
            dropFilesHere: ComponentConstant.DROP_FILE_TO_UPLOAD,
            select: ComponentConstant.SELECT_FILE,
        },
    }, "singleFileUpload");
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx(LocalizationProvider, __assign({ language: "singleFileUpload" }, { children: _jsx(Upload, __assign({ autoUpload: false, showActionButtons: false, files: fieldRenderProps.value, onAdd: onChangeHandler, onRemove: onRemoveHandler }, others)) }))] })));
};
export var DropDownDataLoadOnScroll = function (fieldRenderProps) {
    var validationMessage = fieldRenderProps.validationMessage, touched = fieldRenderProps.touched, id = fieldRenderProps.id, valid = fieldRenderProps.valid, defaultChecked = fieldRenderProps.defaultChecked, disabled = fieldRenderProps.disabled, hint = fieldRenderProps.hint, optional = fieldRenderProps.optional, label = fieldRenderProps.label, visited = fieldRenderProps.visited, modified = fieldRenderProps.modified, isRequired = fieldRenderProps.isRequired, isHorizontal = fieldRenderProps.isHorizontal, wrapperStyle = fieldRenderProps.wrapperStyle, handleScroll = fieldRenderProps.handleScroll, dataList = fieldRenderProps.dataList, onFilterChange = fieldRenderProps.onFilterChange, onDropDownOpen = fieldRenderProps.onDropDownOpen, dataItemKey = fieldRenderProps.dataItemKey, textField = fieldRenderProps.textField, others = __rest(fieldRenderProps, ["validationMessage", "touched", "id", "valid", "defaultChecked", "disabled", "hint", "optional", "label", "visited", "modified", "isRequired", "isHorizontal", "wrapperStyle", "handleScroll", "dataList", "onFilterChange", "onDropDownOpen", "dataItemKey", "textField"]);
    var showValidationMessage = touched && validationMessage;
    var errorId = showValidationMessage ? "".concat(id, "_error") : "";
    var labelId = label ? "".concat(id, "_label") : "";
    return (_jsxs(FieldWrapper, __assign({ style: wrapperStyle }, { children: [_jsxs(Label, __assign({ className: "".concat(isRequired ? "required-field" : ""), id: labelId, editorId: id, editorValid: valid, editorDisabled: disabled }, { children: [label, " ", showValidationMessage && isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage })))] })), showValidationMessage && !isHorizontal && (_jsx(Error, __assign({ id: errorId }, { children: validationMessage }))), _jsx("div", __assign({ onScrollCapture: handleScroll }, { children: _jsx(DropDownListWithValueField, __assign({ data: dataList, dataItemKey: dataItemKey, textField: textField, valueField: fieldRenderProps.dataField, onFilterChange: onFilterChange }, others)) }))] })));
};
export var FieldWIthValidationEnhanced = function (props) {
    var validator = props.validator, name = props.name, others = __rest(props, ["validator", "name"]);
    var enchancedValidator = React.useCallback(function (value) { return validator(__assign({ value: value, name: name }, others)); }, [name]);
    return _jsx(Field, __assign({}, props, { name: name, validator: enchancedValidator }));
};
