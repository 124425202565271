var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
export var DialogBox = function (props) {
    var headingText = props.headingText, bodyText = props.bodyText, cancelText = props.cancelText, isErrorDialog = props.isErrorDialog, acceptText = props.acceptText, onModalChangeState = props.onModalChangeState, isDialogOpen = props.isDialogOpen, acceptHandler = props.acceptHandler, fixedWidth = props.fixedWidth, isContentDialog = props.isContentDialog, children = props.children, _a = props.showActionBar, showActionBar = _a === void 0 ? true : _a, customClass = props.customClass, accept2Text = props.accept2Text, accept2Handler = props.accept2Handler, cancelActionText = props.cancelActionText, cancelActionHandler = props.cancelActionHandler;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var button1Ref = React.useRef(null);
    var button2Ref = React.useRef(null);
    var button3Ref = React.useRef(null);
    var button4Ref = React.useRef(null);
    var toggleDialog = function () {
        setVisible(!visible);
        onModalChangeState(!visible);
    };
    React.useEffect(function () {
        setVisible(isDialogOpen);
    }, [isDialogOpen]);
    React.useEffect(function () {
        var _a, _b, _c, _d;
        if (visible) {
            if (acceptText) {
                (_a = button1Ref === null || button1Ref === void 0 ? void 0 : button1Ref.current) === null || _a === void 0 ? void 0 : _a.focus();
            }
            else if (accept2Text) {
                (_b = button2Ref === null || button2Ref === void 0 ? void 0 : button2Ref.current) === null || _b === void 0 ? void 0 : _b.focus();
            }
            else if (cancelActionText) {
                (_c = button3Ref === null || button3Ref === void 0 ? void 0 : button3Ref.current) === null || _c === void 0 ? void 0 : _c.focus();
            }
            else {
                (_d = button4Ref === null || button4Ref === void 0 ? void 0 : button4Ref.current) === null || _d === void 0 ? void 0 : _d.focus();
            }
        }
    }, [visible, props]);
    var modalStyle = isContentDialog
        ? "common-dialog-box  fixWidth500 ".concat(customClass)
        : "common-dialog-box ".concat(isErrorDialog ? "error-dialog" : "success-dialog", " ").concat(fixedWidth ? "fixWidth500" : "", "  ").concat(props.hideDialogBtns ? "hide-action-btns" : "", " ").concat(customClass ? customClass : "");
    return (_jsx("section", __assign({ className: "common-dialog-box" }, { children: visible && (_jsxs(Dialog, __assign({ title: headingText, className: modalStyle, onClose: toggleDialog }, { children: [isContentDialog ? children : bodyText, showActionBar && (_jsxs(DialogActionsBar, { children: [cancelText && (_jsx("button", __assign({ ref: button4Ref, className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base danger-btn", onClick: toggleDialog, "data-testid": "dialog-button-cancel" }, { children: cancelText }))), cancelActionText && (_jsx("button", __assign({ ref: button3Ref, className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base danger-btn", onClick: cancelActionHandler, "data-testid": "dialog-button-cancel-action" }, { children: cancelActionText }))), accept2Text && (_jsx("button", __assign({ ref: button2Ref, className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ".concat(isErrorDialog ? "danger-btn" : "success-btn"), onClick: accept2Handler, "data-testid": "dialog-button-accept2" }, { children: accept2Text }))), acceptText && (_jsx("button", __assign({ ref: button1Ref, className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base ".concat(isErrorDialog ? "danger-btn" : "success-btn"), onClick: acceptHandler, "data-testid": "dialog-button-accept" }, { children: acceptText })))] }))] }))) })));
};
