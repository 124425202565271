import { AppConstant, ComponentConstant } from "common/constant";
import { isValidRegex, stringToRegex, cleanedZipCode, timeDifferenceInSeconds } from "./utils";
var emailRegex = new RegExp(AppConstant.EMAIL_REG_EXP);
var phoneRegex = new RegExp(AppConstant.PHONE_REG_EXP);
var zipRegex = new RegExp(AppConstant.ZIP_CODE_REG_EXP);
var ssnRegex = new RegExp(AppConstant.SSN_REG_EXP);
var usernamePatter = new RegExp(AppConstant.USERNAME_REG_EXP);
var passwordRegex = new RegExp(AppConstant.PASSWORD_REG_EXP);
var moneyRegex = new RegExp(AppConstant.MONEY_REGEX_EXP);
var compensationRegex = new RegExp(AppConstant.COMPENSATION_REGEX_EXP);
var maskTypeRegex = new RegExp(AppConstant.ALLOWED_MASK_CHAR);
var numRangeRegex = new RegExp(AppConstant.NUM_RANGE_REG_EXP);
var specialCharacterRegex = new RegExp(AppConstant.SPECIAL_CHARACTER_REG_EXP);
export var ZipValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : zipRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_ZIP_INVALID;
};
export var Zip59Validator = function (value) {
    var cleanedZipCodeValue = cleanedZipCode(value);
    return !cleanedZipCodeValue
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : cleanedZipCodeValue.length < 5 ||
            (cleanedZipCodeValue.length > 5 && cleanedZipCodeValue.length < 9)
            ? ComponentConstant.ERROR_ZIP_59_INVALID
            : ComponentConstant.EMPTY_STRING;
};
export var Zip59ValidatorRequiedOptional = function (value) {
    var cleanedZipCodeValue = cleanedZipCode(value);
    return !cleanedZipCodeValue
        ? ComponentConstant.EMPTY_STRING
        : cleanedZipCodeValue.length < 5 ||
            (cleanedZipCodeValue.length > 5 && cleanedZipCodeValue.length < 9)
            ? ComponentConstant.ERROR_ZIP_59_INVALID
            : ComponentConstant.EMPTY_STRING;
};
export var SSNValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : ssnRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_SSN_INVALID;
};
export var EIDValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : ssnRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_EIN_INVALID;
};
export var TRNValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : ssnRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_TRN_INVALID;
};
export var maskTypeValidator = function (value) {
    return value
        ? maskTypeRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_MASK_TYPE
        : ComponentConstant.EMPTY_STRING;
};
export var termsValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_MESSAGE_TERMS_CONDITION;
};
export var emailValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_MESSAGE_EMAIL_REQUIRED
        : emailRegex.test(value === null || value === void 0 ? void 0 : value.trim())
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_MESSAGE_EMAIL_INVALID;
};
export var optionalEmailValidator = function (value) {
    return !value
        ? ComponentConstant.EMPTY_STRING
        : emailRegex.test(value === null || value === void 0 ? void 0 : value.trim())
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_MESSAGE_EMAIL_INVALID;
};
export var phoneValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : phoneRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_PHONE_INVALID;
};
export var optionalPhoneNumberValidation = function (value) {
    var cleanMaskedValue = cleanedZipCode(value);
    return !cleanMaskedValue
        ? ComponentConstant.EMPTY_STRING
        : cleanMaskedValue.length < 10
            ? ComponentConstant.ERROR_10_DIGIT_PHONE_NUMBER
            : ComponentConstant.EMPTY_STRING;
};
export var TermsnConditionValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.PAYREEL_T_C_AGREEMENT;
};
export var signaturevalidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.UPLOAD_DOCUMENT_SIGNATURE_REQUIRED;
};
export var requiredValidator = function (value) {
    return (value === null || value === void 0 ? void 0 : value.toString().trim())
        ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
            ? ComponentConstant.ERROR_FIELD_REQUIRED
            : ComponentConstant.EMPTY_STRING
        : ComponentConstant.ERROR_FIELD_REQUIRED;
};
export var requiredFedralValidator = function (value) {
    return (value === null || value === void 0 ? void 0 : value.value)
        ? (value === null || value === void 0 ? void 0 : value.value) === null
            ? ComponentConstant.ERROR_FIELD_REQUIRED
            : ComponentConstant.EMPTY_STRING
        : ComponentConstant.ERROR_FIELD_REQUIRED;
};
export var userNameValidator = function (value, passwordState) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : usernamePatter.test(value)
            ? specialCharacterRegex.test(value)
                ? value === passwordState
                    ? ComponentConstant.USERNAME_PASSWORD_SAME_ERROR_VALIDATION
                    : ComponentConstant.EMPTY_STRING
                : ComponentConstant.ERROR_SPECIAL_CHARACTER
            : ComponentConstant.ERROR_USERNAME;
};
export var passwordValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : passwordRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_PASSWORD;
};
export var groupNameDuplicateValidator = function (value) {
    return value === null ? ComponentConstant.EMPTY_STRING : ComponentConstant.GROUP_NAME_ALREADY_EXIST;
};
export var optionsFormatValidator = function (value) {
    return value === null ? ComponentConstant.EMPTY_STRING : ComponentConstant.ATTR_FORMAT_SHOULD_CORRECT;
};
export var attNameDuplicateValidator = function (value) {
    return value === null ? ComponentConstant.EMPTY_STRING : ComponentConstant.ATT_NAME_ALREADY_EXIST;
};
export var jobEndDateValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_MESSAGE_END_DATE_SMALLER;
};
export var requiredEndDateValidator = function (value) {
    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    if (value === null || value === undefined) {
        return ComponentConstant.EMPTY_STRING;
    }
    else if (new Date(value).getTime() < todayDate.getTime() && value !== undefined) {
        return ComponentConstant.UPLOAD_FORM_INVALID_END_DATE;
    }
    else {
        return ComponentConstant.EMPTY_STRING;
    }
};
export var FormEndDateValidator = function (value) {
    return ComponentConstant.ERROR_MESSAGE_END_DATE_SMALLER;
};
export var jobStartDateMinValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_MESSAGE_START_DATE_MINIMUM;
};
export var startTimeValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_MESSAGE_START_TIME_MINIMUM;
};
export var invalidHours = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.HOURS_NOT_AVAILABLE_SICK_LEAVE;
};
export var validMealHours0to24 = function (value) {
    var result = null;
    if ((value !== "" && value !== null && value !== undefined && isNaN(value)) ||
        value < 0 ||
        value > 24) {
        result = false;
    }
    return result === null
        ? ComponentConstant.EMPTY_STRING
        : ComponentConstant.ERROR_MESSAGE_INVALID_MEAL_HOURS;
};
export var requiredDateValidator = function (value) {
    var todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    if (new Date(value).getTime() < todayDate.getTime() && value !== undefined) {
        return ComponentConstant.UPLOAD_FORM_INVALID_DATE;
    }
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_FIELD_REQUIRED;
};
export var docUploadValidator = function (value) {
    var result = value;
    if (value === null || (value === null || value === void 0 ? void 0 : value.newHireDocumentTypeID) === null || value === undefined) {
        result = null;
    }
    return result ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_FIELD_REQUIRED;
};
export var moneyValidator = function (value) {
    return !(value === null || value === void 0 ? void 0 : value.toString())
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : moneyRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_MONEY_INVALID;
};
export var maxMinRangeValidator = function (value) {
    return value
        ? numRangeRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_NUM_VALUE
        : ComponentConstant.EMPTY_STRING;
};
export var notRequiredMoneyValidator = function (value) {
    return value
        ? moneyRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_MONEY_INVALID
        : ComponentConstant.EMPTY_STRING;
};
export var compensationMoneyValidator = function (value) {
    return !(value === null || value === void 0 ? void 0 : value.toString())
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : compensationRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_MONEY_INVALID;
};
export var ConfirmPasswordValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.ERROR_CONFIRM_PASSWORD;
};
export var requiredAndSpecialCharNotAllowedValidator = function (value) {
    return !value
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : specialCharacterRegex.test(value)
            ? ComponentConstant.EMPTY_STRING
            : ComponentConstant.ERROR_SPECIAL_CHARACTER;
};
export var dynamicFormMultipleValidator = function (value, valueGetter, name, validationRules) {
    try {
        var errorMsg = "";
        for (var _i = 0, validationRules_1 = validationRules; _i < validationRules_1.length; _i++) {
            var validation = validationRules_1[_i];
            errorMsg = "";
            var requiredDefaultMsg = ComponentConstant.ERROR_FIELD_REQUIRED.replace(/[()]/g, "");
            if (validation === null || validation === void 0 ? void 0 : validation.isRequired) {
                if (validation === null || validation === void 0 ? void 0 : validation.isStateValidation) {
                    if (!(value === null || value === void 0 ? void 0 : value.stateID)) {
                        errorMsg = requiredDefaultMsg;
                        break;
                    }
                }
                else {
                    var displayMsg = (validation === null || validation === void 0 ? void 0 : validation.validationMessage) || requiredDefaultMsg;
                    var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                        ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                        : "(".concat(displayMsg, ")");
                    if (getMessage !== "") {
                        errorMsg = getMessage;
                        break;
                    }
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.minDate) {
                var displayMsg = validation.validationMessage || "Min allowed date is ".concat(validation === null || validation === void 0 ? void 0 : validation.minDate);
                var enterDate = value ? new Date(value) : new Date();
                var minDate = new Date(validation === null || validation === void 0 ? void 0 : validation.minDate);
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : enterDate.getTime() < minDate.getTime()
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.maxDate) {
                var displayMsg = validation.validationMessage || "Max allowed date is ".concat(validation === null || validation === void 0 ? void 0 : validation.maxDate);
                var enterDate = value ? new Date(value) : new Date();
                var maxDate = new Date(validation === null || validation === void 0 ? void 0 : validation.maxDate);
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : enterDate.getTime() > maxDate.getTime()
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.minLength) {
                var displayMsg = validation.validationMessage ||
                    "Enter more than ".concat(validation === null || validation === void 0 ? void 0 : validation.minLength, " characters");
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : value.length < parseInt(validation.minLength)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.maxLength) {
                var displayMsg = validation.validationMessage ||
                    "Maximum ".concat(validation === null || validation === void 0 ? void 0 : validation.maxLength, " characters are allowed");
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : value.length > parseInt(validation.maxLength)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.min) {
                var displayMsg = validation.validationMessage || "Minimum limit is ".concat(validation === null || validation === void 0 ? void 0 : validation.min);
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : value < parseInt(validation === null || validation === void 0 ? void 0 : validation.min)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if ((validation === null || validation === void 0 ? void 0 : validation.allowNegative) && (validation === null || validation === void 0 ? void 0 : validation.allowNegative) === "false") {
                var displayMsg = validation.validationMessage || "Negative not allowed";
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : value < 0
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if ((validation === null || validation === void 0 ? void 0 : validation.allowDecimal) && (validation === null || validation === void 0 ? void 0 : validation.allowDecimal) === "false") {
                var displayMsg = validation.validationMessage || "Decimal not allowed";
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : !Number.isInteger(value)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.min) {
                var displayMsg = validation.validationMessage || "Minimum limit is ".concat(validation === null || validation === void 0 ? void 0 : validation.min);
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : value < parseInt(validation === null || validation === void 0 ? void 0 : validation.min)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.max) {
                var displayMsg = validation.validationMessage || "Maximum limit is ".concat(validation === null || validation === void 0 ? void 0 : validation.max);
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : value > parseInt(validation === null || validation === void 0 ? void 0 : validation.max)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.pattern) {
                var stringRegex = "".concat(validation === null || validation === void 0 ? void 0 : validation.pattern).trim();
                var isRegexValid = isValidRegex(stringRegex);
                if (isRegexValid) {
                    var displayMsg = validation.validationMessage || "Please enter valid data.";
                    var patternRegex = stringToRegex(stringRegex);
                    var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                        ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                            ? ComponentConstant.ERROR_FIELD_REQUIRED
                            : patternRegex.test(value)
                                ? ComponentConstant.EMPTY_STRING
                                : "(".concat(displayMsg, ")")
                        : ComponentConstant.EMPTY_STRING;
                    if (getMessage !== "") {
                        errorMsg = getMessage;
                        break;
                    }
                }
            }
            else if ((validation === null || validation === void 0 ? void 0 : validation.allowSpecialCharacter) == "false") {
                var patternRegex = new RegExp(/^[a-zA-Z0-9 ]*$/);
                var displayMsg = validation.validationMessage || "Special character(s) are not allowed";
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : patternRegex.test(value)
                            ? ComponentConstant.EMPTY_STRING
                            : "(".concat(displayMsg, ")")
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.restrictedCharacter) {
                var splitChar = validation === null || validation === void 0 ? void 0 : validation.restrictedCharacter.split("");
                var displayMsg = validation.validationMessage ||
                    "".concat(validation === null || validation === void 0 ? void 0 : validation.restrictedCharacter, " character(s) not allowed");
                var getMessage = (value === null || value === void 0 ? void 0 : value.toString().trim())
                    ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : splitChar.some(function (char) { return value.includes(char); })
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
            else if (validation === null || validation === void 0 ? void 0 : validation.mask) {
                var valueAfterRemoveUnderScore = value === null || value === void 0 ? void 0 : value.replace("_", "");
                var displayMsg = validation.validationMessage || "Please enter valid data.";
                var getMessage = (valueAfterRemoveUnderScore === null || valueAfterRemoveUnderScore === void 0 ? void 0 : valueAfterRemoveUnderScore.toString().trim())
                    ? (valueAfterRemoveUnderScore === null || valueAfterRemoveUnderScore === void 0 ? void 0 : valueAfterRemoveUnderScore.toString().trim()) === ""
                        ? ComponentConstant.ERROR_FIELD_REQUIRED
                        : (valueAfterRemoveUnderScore === null || valueAfterRemoveUnderScore === void 0 ? void 0 : valueAfterRemoveUnderScore.length) < (validation === null || validation === void 0 ? void 0 : validation.mask.length)
                            ? "(".concat(displayMsg, ")")
                            : ComponentConstant.EMPTY_STRING
                    : ComponentConstant.EMPTY_STRING;
                if (getMessage !== "") {
                    errorMsg = getMessage;
                    break;
                }
            }
        }
        return errorMsg;
    }
    catch (error) {
        console.log({ error: error });
    }
};
export var empDateOfBirthValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.EMPLOYEE_DATE_OF_BIRTH_INVALID;
};
export var maxFiveNumberValidator = function (value) {
    var stringValue = value !== null && value !== "" && value !== undefined ? value.toString().trim() : null;
    return stringValue === null || stringValue === "" || stringValue === undefined
        ? ComponentConstant.ERROR_FIELD_REQUIRED
        : stringValue.length > 5
            ? ComponentConstant.ERROR_FIVE_NUM_VALUE
            : ComponentConstant.EMPTY_STRING;
};
export var validateParenthesis = function (inputString) {
    var parenthesesStack = [];
    var openingPairs = ["(", "["];
    var closingPairs = [")", "]"];
    for (var _i = 0, inputString_1 = inputString; _i < inputString_1.length; _i++) {
        var char = inputString_1[_i];
        if (openingPairs.includes(char)) {
            parenthesesStack.push(char);
        }
        else if (closingPairs.includes(char)) {
            var lastOpening = parenthesesStack.pop();
            var expectedClosing = openingPairs[closingPairs.indexOf(char)];
            if (lastOpening !== expectedClosing) {
                return false; // Unbalanced closing parenthesis
            }
        }
    }
    return parenthesesStack.length === 0; // Return true if all parentheses are balanced
};
export var checkBrackets = function (str) {
    var stack = [];
    for (var i = 0; i < str.length; i++) {
        var char = str[i];
        if (char === "(") {
            stack.push("(");
        }
        else if (char === ")") {
            if (stack.length === 0 || stack.pop() !== "(") {
                return false;
            }
        }
    }
    return stack.length === 0;
};
var validateStringContainsRegex = new RegExp(/^[\w\s()[\]!&&||<>=.'+\-*/]+$/);
var validateOperatorsOccueranceRegex = /^(?!.*?(AND|OR|NOT)(?:\s+(?:AND|OR|NOT)))/i;
export var validateExpression = function (expression) {
    var _a;
    var value = (_a = expression === null || expression === void 0 ? void 0 : expression.trimStart()) === null || _a === void 0 ? void 0 : _a.trimEnd();
    if (!value) {
        return "This field is required";
    }
    else if (!checkBrackets(value)) {
        return "Should start and end with () paranthesis!";
    }
    else if (!validateOperatorsOccueranceRegex.test(value)) {
        return "Operator AND, OR and NOT can not followed themself and each other!";
    }
    else if (!validateStringContainsRegex.test(value)) {
        return "Invalid characters or symbols!";
    }
    else if (!validateParenthesis(value)) {
        return "Paranthesis () or [] mismatched!";
    }
    else {
        return "";
    }
};
export var requiredValidatorWithCustomText = function (value, text) {
    return (value === null || value === void 0 ? void 0 : value.toString().trim())
        ? (value === null || value === void 0 ? void 0 : value.toString().trim()) === ""
            ? text
            : ComponentConstant.EMPTY_STRING
        : text;
};
export var numericValueValidator = function (value) {
    return isNaN(Number(value)) ? ComponentConstant.ERROR_ALL_NUMBERS : ComponentConstant.EMPTY_STRING;
};
export var formMaskedTypeValidator = function (value) {
    return (value === null || value === void 0 ? void 0 : value.replace(/[^0-9]/g, ""))
        ? (value === null || value === void 0 ? void 0 : value.replace(/[^0-9]/g, "")) === ""
            ? ComponentConstant.ERROR_FIELD_REQUIRED
            : (value === null || value === void 0 ? void 0 : value.replace(/[^0-9]/g, "").length) !== 5
                ? ComponentConstant.INVALID_INPUT
                : ComponentConstant.EMPTY_STRING
        : ComponentConstant.ERROR_FIELD_REQUIRED;
};
// Validates time to be in the range XX00-XX59. Hours > 24 are required
// for entering the end of a work period that soans mifnight.
export var timeValidator = function (value) {
    var regex = new RegExp(AppConstant.ADD_IN_OUT_TIME_REGEX);
    if (!value) {
        return ComponentConstant.REQUIRED_VALIDATION_TEXT;
    }
    else if (regex.test(value) !== true) {
        return ComponentConstant.NOT_VALID_TIME;
    }
    return ComponentConstant.EMPTY_STRING;
};
export var numericnDecimalTypeValidator = function (value) {
    var decimalPattern = new RegExp(AppConstant.TWO_DECIMAL_REGEX);
    var decimalCheck = decimalPattern.test(value === null || value === void 0 ? void 0 : value.toString());
    return value
        ? isNaN(Number(value))
            ? ComponentConstant.NUMBER_OR_DECIMAL_TYPE_VALIDATOR
            : !decimalCheck
                ? ComponentConstant.NUMBER_OR_DECIMAL_TYPE_VALIDATOR
                : ComponentConstant.EMPTY_STRING
        : ComponentConstant.EMPTY_STRING;
};
export var yearValidator = function (date) {
    var _a;
    if (!date) {
        return ComponentConstant.ERROR_FIELD_REQUIRED;
    }
    else if (new Date(date).getTime() > new Date(2099, 12, 31).getTime() ||
        new Date(date).getTime() < new Date(((_a = new Date()) === null || _a === void 0 ? void 0 : _a.getFullYear()) - 5, 1, 1).getTime()) {
        return ComponentConstant.YEAR_FIELD_MIN_MAX_RANGE_ERROR;
    }
    else {
        return ComponentConstant.EMPTY_STRING;
    }
};
export var pdfValidator = function (file) {
    return file[0].extension !== AppConstant.DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS[0]
        ? ComponentConstant.PDF_VALIDATION_ERROR_TEXT
        : ComponentConstant.EMPTY_STRING;
};
export var eventStartEndDateValidator = function (value) {
    return value ? ComponentConstant.EMPTY_STRING : ComponentConstant.EVENT_START_END_DATE_VALIDATOR;
};
export var optionalSSNValidation = function (value) {
    if (value) {
        var cleanedValue = value === null || value === void 0 ? void 0 : value.toString().replace(/\D/g, "");
        return cleanedValue
            ? "".concat(cleanedValue).length < 4
                ? ComponentConstant.INVALID_SSN
                : ComponentConstant.EMPTY_STRING
            : ComponentConstant.EMPTY_STRING;
    }
    else {
        return ComponentConstant.EMPTY_STRING;
    }
};
export var documentValidator = function (files, AllowedExtensions) {
    // Check if any file's extension is invalid
    var invalidFile = files.find(function (file) { var _a; return !AllowedExtensions.includes((_a = file === null || file === void 0 ? void 0 : file.extension) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
    // Return an appropriate response
    return invalidFile ? ComponentConstant.INVALID_DOCUMENT : ComponentConstant.EMPTY_STRING;
};
export var amountRangeValidator = function (value) {
    if (Number(value) > Number(9999999.99)) {
        return ComponentConstant.MAX_AMOUNT_VALIDATOR;
    }
    return null;
};
export var noValidationMsg = function (value) {
    return null;
};
export var mealHoursValidation = function (props) {
    var timeInName = props.timeInName, timeOutName = props.timeOutName, formRenderProps = props.formRenderProps, name = props.name;
    var timeIn = formRenderProps.valueGetter(timeInName);
    var timeOut = formRenderProps.valueGetter(timeOutName);
    var value = formRenderProps.valueGetter(name);
    if (!!timeIn && !!timeOut && !!value) {
        var timeInOutDiff = timeDifferenceInSeconds(timeIn, timeOut);
        var mealHoursAdded = Number(value) * 60 * 60;
        var hoursDifference = timeInOutDiff - mealHoursAdded;
        var validMinimumWorkTime = 0;
        if (hoursDifference < validMinimumWorkTime) {
            return ComponentConstant.INVALID_MEAL_HOURS_MESSAGE;
        }
        else {
            return ComponentConstant.EMPTY_STRING;
        }
    }
};
export var timeInOutValidation = function (props) {
    var timeOutName = props.timeOutName, timeInName = props.timeInName, formRenderProps = props.formRenderProps, name = props.name;
    var timeOut = formRenderProps.valueGetter(timeOutName);
    var timeIn = formRenderProps.valueGetter(timeInName);
    var value = formRenderProps.valueGetter(name);
    var validationMsg = timeValidator(value);
    if (validationMsg === ComponentConstant.EMPTY_STRING) {
        if (!!timeIn && !!timeOut) {
            var timeInValue = timeIn.replace(":", "");
            var timeOutValue = timeOut.replace(":", "");
            if (timeInValue >= timeOutValue) {
                return ComponentConstant.TIMEOUT_OVERLAP_TIMEIN;
            }
            else {
                return ComponentConstant.EMPTY_STRING;
            }
        }
    }
    else {
        return validationMsg;
    }
};
export var employeeNumberValidation = function (maskValue) {
    var value = maskValue === null || maskValue === void 0 ? void 0 : maskValue.replace(/_/g, "");
    if (!value)
        return ComponentConstant.ERROR_FIELD_REQUIRED;
    if ((value === null || value === void 0 ? void 0 : value.length) < 6)
        return ComponentConstant.INVALID_INPUT;
    return "";
};
