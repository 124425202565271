var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppConstant } from "common/constant";
import { useCurrentViewContext } from "store/common/currentViewContext";
var Button = function (_a) {
    var _b = _a.autoFocus, autoFocus = _b === void 0 ? false : _b, _c = _a.buttonClass, buttonClass = _c === void 0 ? "" : _c, buttonClickHandler = _a.buttonClickHandler, _d = _a.buttonIconClass, buttonIconClass = _d === void 0 ? "" : _d, _e = _a.buttonText, buttonText = _e === void 0 ? "" : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.disabledForReadOnlyRoles, disabledForReadOnlyRoles = _g === void 0 ? false : _g, _h = _a.id, id = _h === void 0 ? "" : _h, _j = _a.style, style = _j === void 0 ? {} : _j, _k = _a.type, type = _k === void 0 ? "button" : _k;
    var sanitizedType = type === "submit" || type === "reset" ? type : "button";
    var userAccessType = useCurrentViewContext().userAccessType;
    var accessDisabledClass = userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE && disabledForReadOnlyRoles
        ? "access-disabled"
        : "";
    return (_jsxs("button", __assign({ autoFocus: autoFocus, className: "k-button ".concat(buttonClass, " ").concat(accessDisabledClass), disabled: disabled, id: id, onClick: function () { return buttonClickHandler(); }, type: sanitizedType, style: style, "data-testid": "button-".concat(id) }, { children: [buttonIconClass && _jsx("i", { className: "fa ".concat(buttonIconClass) }), buttonText] })));
};
export default Button;
