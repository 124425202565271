var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormDropDownList } from "components/shared/FormComponents";
import { localizedMessages } from "components/shared/utils";
import useUpdateDialogContent from "hooks/useUpdateDialogContent";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppConstant, ComponentConstant, DialogBox, FormButtons, FormInput, Transform, TranslationConstant, createEncodedURL, decodedURL, requiredValidator, useSearchEmpViewContext, } from "../index";
// Default item for dropdown lists
export var DEFAULT_ITEM = {
    text: localizedMessages.LABEL_DEFAULT_OPTION_SELECT,
    value: null,
};
// Component to add a new location
export var AddNewLocation = function (_a) {
    var handleClose = _a.handleClose;
    var navigate = useNavigate();
    var formRef = useRef(null); // Ref for the form element
    var _b = decodedURL(), workLocationID = _b.workLocationID, formRedirectData = _b.formRedirectData; // Decode URL to retrieve relevant parameters
    // Dialog content and visibility state management
    var _c = useUpdateDialogContent(), setDialogContent = _c.setDialogContent, showDialog = _c.showDialog, setShowDialog = _c.setShowDialog, dialogContent = _c.dialogContent;
    // Context for API calls and data fetching
    var _d = useSearchEmpViewContext(), postSaveLocationMapping = _d.postSaveLocationMapping, countriesList = _d.countriesList, fetchStateFromCountryList = _d.fetchStateFromCountryList, stateFromCountryList = _d.stateFromCountryList;
    // State for managing new location data
    var _e = useState({
        countryID: null,
    }), newLocationData = _e[0], setNewLocationData = _e[1];
    // Handle form submission
    var handleSubmit = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, country, stateLongName, stateShortName, city, zip, dataObj, response, err_1;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    // Check if the submitted form data is valid
                    if (!dataItem.isValid)
                        return [2 /*return*/];
                    _a = dataItem.values, country = _a.country, stateLongName = _a.stateLongName, stateShortName = _a.stateShortName, city = _a.city, zip = _a.zip;
                    dataObj = {
                        country: country.text,
                        stateLongName: (stateLongName === null || stateLongName === void 0 ? void 0 : stateLongName.text) || (stateLongName === null || stateLongName === void 0 ? void 0 : stateLongName.trim()),
                        stateShortName: stateShortName,
                        city: city === null || city === void 0 ? void 0 : city.trim(),
                        zip: zip === null || zip === void 0 ? void 0 : zip.trim(),
                    };
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, postSaveLocationMapping(dataObj)];
                case 2:
                    response = _d.sent();
                    // Check if the API response was successful
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status) &&
                        ((_b = response.data) === null || _b === void 0 ? void 0 : _b.message) === "") {
                        // Handle successful save
                        setNewLocationData(response === null || response === void 0 ? void 0 : response.data);
                        setDialogContent({
                            headingText: TranslationConstant.SUCCESS,
                            displayMsg: TranslationConstant.NEW_LOCATION_SUCCESS_HEADING,
                        });
                    }
                    else {
                        // Handle API response errors
                        setDialogContent({
                            headingText: TranslationConstant.FAILED,
                            staticMsg: (_c = response.data) === null || _c === void 0 ? void 0 : _c.message,
                            isErrorDialog: true,
                        });
                    }
                    return [3 /*break*/, 5];
                case 3:
                    err_1 = _d.sent();
                    setDialogContent({
                        headingText: TranslationConstant.FAILED,
                        displayMsg: TranslationConstant.ERROR_IN_SAVE_LOCATION_MAPPING,
                        isErrorDialog: true,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    // Always show the dialog box after submission
                    setShowDialog(true);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // Handle navigation to replace the current URL with updated parameters
    var handleReplace = function () {
        var urlParams = {};
        if (newLocationData === null || newLocationData === void 0 ? void 0 : newLocationData.countryID) {
            urlParams.updatedFormData = __assign(__assign({}, newLocationData), { formRedirectData: formRedirectData, workLocationID: workLocationID });
        }
        else {
            urlParams.formRedirectData = formRedirectData;
            urlParams.workLocationID = workLocationID;
        }
        var newUrl = createEncodedURL("/addworklocation", urlParams);
        navigate(newUrl, { replace: true });
        handleClose();
    };
    /**
     * Check if the given countryId is for USA or Canada.
     * @param {number} countryId - The ID of the country to check.
     * @returns {boolean} true if the country is USA or Canada, false otherwise.
     */
    var isCountryUSAorCanada = function (countryId) {
        return [AppConstant.USA_COUNTRY_ID, AppConstant.CANADA_COUNTRY_ID].includes(countryId);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, __assign({ title: _jsx(Transform, { mkey: TranslationConstant.ADD_NEW_LOCATION_HEADING }), className: "common-dialog-box success-dialog hide-action-btns form-dialog" }, { children: _jsx("div", __assign({ className: "flexible-wrapper-500 py-0" }, { children: _jsx(Form, { onSubmitClick: handleSubmit, ref: formRef, render: function (formRenderProps) {
                            var _a, _b;
                            return (_jsxs(FormElement, __assign({ className: "general-form border-0 m-0" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row pt-2" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-2" }, { children: _jsx(Field, { id: "country", name: "country", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_COUNTRY }), textField: "text", valueField: "value", data: countriesList, component: FormDropDownList, defaultItem: DEFAULT_ITEM, onChange: function (e) {
                                                        var value = e.value.value;
                                                        if (isCountryUSAorCanada(value)) {
                                                            fetchStateFromCountryList(value);
                                                        }
                                                    }, isRequired: true, validator: requiredValidator }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-2" }, { children: _jsx(Field, __assign({ id: "stateLongName", name: "stateLongName", isRequired: true, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATE }) }, (isCountryUSAorCanada((_a = formRenderProps.valueGetter("country")) === null || _a === void 0 ? void 0 : _a.value)
                                                    ? {
                                                        component: FormDropDownList,
                                                        textField: "text",
                                                        valueField: "value",
                                                        data: stateFromCountryList,
                                                        defaultItem: DEFAULT_ITEM,
                                                        onChange: function (e) {
                                                            var attributes = e.value.attributes;
                                                            formRenderProps.onChange("stateShortName", {
                                                                value: attributes.ShortName,
                                                            });
                                                        },
                                                    }
                                                    : {
                                                        placeholder: ComponentConstant.PLACEHOLDER_LABEL_STATE,
                                                        maxLength: 100,
                                                        component: FormInput,
                                                    }))) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-2" }, { children: _jsx(Field, { id: "stateShortName", name: "stateShortName", component: FormInput, isRequired: true, validator: requiredValidator, maxLength: 15, disabled: isCountryUSAorCanada((_b = formRenderProps.valueGetter("country")) === null || _b === void 0 ? void 0 : _b.value), label: _jsx(Transform, { mkey: TranslationConstant.LABEL_SHORT_STATE }), placeholder: ComponentConstant.PLACEHOLDER_LABEL_SHORT_STATE }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-2" }, { children: _jsx(Field, { id: "city", name: "city", component: FormInput, isRequired: true, validator: requiredValidator, maxLength: 100, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_CITY }), placeholder: ComponentConstant.PLACEHOLDER_LABEL_CITY }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-1" }, { children: _jsx(Field, { id: "zip", name: "zip", component: FormInput, isRequired: true, validator: requiredValidator, maxLength: 100, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_ZIP }), placeholder: ComponentConstant.PLACEHOLDER_ZIP }) }))] })), _jsx(FormButtons, { searchType: false, closeText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), showCancelButton: true, hideResetButton: true, cancelHandler: handleReplace })] })));
                        } }) })) })), _jsx(DialogBox, __assign({}, dialogContent, { isDialogOpen: showDialog, onModalChangeState: function () { return setShowDialog(false); } }))] }));
};
